import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import {
  popperVariation,
  TippyModule,
  tooltipVariation,
} from '@ngneat/helipopper';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { TranslocoModule } from '@ngneat/transloco';
import {
  DxButtonModule,
  DxLoadIndicatorModule,
  DxLoadPanelModule,
  DxSelectBoxModule,
  DxTextBoxModule,
} from 'devextreme-angular';
import {
  ActionBarComponent,
  ActionButtonComponent,
  AlertComponent,
  FileUploadComponent,
  FormButtonComponent,
  IconComponent,
  InfoBlockComponent,
  MainHeadingComponent,
  NotEnoughPermissionsComponent,
  PageNotFoundComponent,
  ProductLogoComponent,
  SpinnerIconComponent,
  SpinnerPanelComponent,
} from './components';
import { ActionLinkComponent } from './components/action-link/action-link.component';
import { ButtonSetComponent } from './components/button-set/button-set.component';
import { ButtonComponent } from './components/button/button.component';
import { ConfirmComponent } from './components/confirm-popover/confirm-popover.component';
import { GenericErrorComponent } from './components/generic-error/generic-error.component';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { LoginComponent } from './components/login/login.component';
import { PagerComponent } from './components/pager/pager.component';
import { TranslatedSelectComponent } from './components/translated-select/translated-select.component';
import { completeIconSet } from './svg-icons/svg-icons';
@NgModule({
  imports: [
    CommonModule,

    DxButtonModule,
    DxLoadIndicatorModule,
    DxLoadPanelModule,
    DxTextBoxModule,
    DxSelectBoxModule,
    MatRippleModule,
    TranslocoModule,
    TippyModule.forRoot({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
      },
    }),

    SvgIconsModule.forRoot({
      icons: completeIconSet,
    }),
  ],
  declarations: [
    ActionBarComponent,
    ActionButtonComponent,
    ActionLinkComponent,
    AlertComponent,
    ButtonSetComponent,
    FileUploadComponent,
    FormButtonComponent,
    IconComponent,
    InfoBlockComponent,
    LoadingIndicatorComponent,
    MainHeadingComponent,
    NotEnoughPermissionsComponent,
    PageNotFoundComponent,
    ProductLogoComponent,
    SpinnerIconComponent,
    SpinnerPanelComponent,
    PagerComponent,
    ConfirmComponent,
    LoginComponent,
    GenericErrorComponent,
    TranslatedSelectComponent,
    ButtonComponent,
  ],
  exports: [
    ActionBarComponent,
    ActionButtonComponent,
    ActionLinkComponent,
    AlertComponent,
    ButtonComponent,
    ButtonSetComponent,
    FileUploadComponent,
    FormButtonComponent,
    IconComponent,
    InfoBlockComponent,
    LoadingIndicatorComponent,
    MainHeadingComponent,
    NotEnoughPermissionsComponent,
    ProductLogoComponent,
    SpinnerIconComponent,
    SpinnerPanelComponent,
    PagerComponent,
    ConfirmComponent,
    TranslatedSelectComponent,
  ],
})
export class SharedUiModule {}
