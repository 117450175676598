<div class="logo">
  <img [src]="popupContentImageSrc" [alt]="popupContentImageSrc" />
</div>

<div class="container">
  <div class="address">
    <p>Adresa:</p>
    <span> {{ productConfig.companyFullName }} </span><br />
    <span> {{ productConfig.address.street }} </span><br />
    <span> {{ productConfig.address.postalCode }} </span><br />
    <span> {{ productConfig.address.city }} </span><br />
  </div>

  <div class="contacts">
    <p>Kontakt:</p>
    <a [href]="'tel:' + productConfig.telephoneNumber">
      {{ productConfig.telephoneNumber }}</a
    >
    <br />
    <a [href]="'mailto:' + productConfig.emailAddress">{{
      productConfig.emailAddress
    }}</a>
    <br />
    <br />
  </div>
</div>
<p></p>
