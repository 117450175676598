import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScreenService } from './screen.service';

@Injectable({
  providedIn: 'root',
})
export class TreeNavigationUiService {
  private treeNavToggleSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    this.screen.sizes['screen-large']
  );

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public treeNavOpened$ = this.treeNavToggleSubject.asObservable();

  constructor(private screen: ScreenService) {}

  toggleTreeNav(value?: boolean) {
    if (isBoolean(value)) {
      this.treeNavToggleSubject.next(value);
    } else {
      const oppositeState = !this.treeNavToggleSubject.getValue();
      this.treeNavToggleSubject.next(oppositeState);
    }
  }
}

function isBoolean(value: boolean) {
  return typeof value === typeof true;
}
