import { registerLocaleData } from '@angular/common';
import localeCs from '@angular/common/locales/cs';
import { Component, Inject, OnInit } from '@angular/core';
import {
  DynamicConfigService,
  InitialConfiguration,
  NavigationServiceInterface,
  NAVIGATION_SERVICE,
  ProductConfig,
  StaticConfigService,
  TreeNode,
} from '@domat/shared/data-access';
import {
  ENV,
  Environment,
  LocalizationConfigService,
  LoggingConfigService,
  ScreenService,
  TreeNavigationUiService,
} from '@domat/shared/utils';
import Scrollable from 'devextreme/ui/scroll_view/ui.scrollable';
import dxTreeView from 'devextreme/ui/tree_view';

registerLocaleData(localeCs, 'cs');

@Component({
  selector: 'domat-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit {
  productConfig: ProductConfig;
  initialConfig: InitialConfiguration;
  breadcrumbs: TreeNode[] = [];
  maxDrawerWidth: number;

  menuMode = 'shrink';
  menuRevealMode = 'slide';
  minMenuSize = 0;
  shaderEnabled = false;
  pageRequiresClosedTreeMenu: boolean = false;
  pageRequiresFullWidthAndHeight: boolean = false;

  constructor(
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface,
    @Inject(ENV) private env: Environment,
    private dynamicConfig: DynamicConfigService,
    private localizationConfig: LocalizationConfigService,
    private loggingConfig: LoggingConfigService,
    private screen: ScreenService,
    private staticConfig: StaticConfigService,
    public treeNavService: TreeNavigationUiService
  ) {
    this.productConfig = this.staticConfig.productConfig;
    this.initialConfig = this.dynamicConfig.appConfig;
    this.maxDrawerWidth = 280;

    this.setScrollbarAlwaysVisible();
  }

  ngOnInit() {
    this.localizationConfig.configureDevextreme(
      this.dynamicConfig.appConfig.user.language
    );
    this.loggingConfig.configure(
      this.env.production,
      this.dynamicConfig.appConfig.environment.isDebug
    );
    this.navigation.keepAppTitleUpdated();
    this.keepDrawerUpdated();
    this.handleSpecialPages();
  }

  drawerOptionChanged(event) {
    if (event.name === 'opened') {
      this.treeNavService.toggleTreeNav(event.value);
    }
  }

  updateBreadcrumbs(breadcrumbs: TreeNode[]) {
    this.breadcrumbs = breadcrumbs;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap';
  }

  treeNodeClicked(e) {
    if (!e.itemData || !e.itemData.id) {
      // global view
      this.updateBreadcrumbs([]);
    }
    if (this.hideMenuAfterNavigation) {
      this.treeNavService.toggleTreeNav(false);
    }
  }

  private keepDrawerUpdated() {
    this.screen.changed.subscribe(() => this.updateDrawer());
    this.updateDrawer();
  }

  private handleSpecialPages() {
    this.navigation.currentPrimaryPageId$.subscribe((pageId) => {
      const pagesWithHiddenTreeMenu =
        this.productConfig.pagesWithHiddenTreeMenu;
      this.updateDrawer(
        pagesWithHiddenTreeMenu && pagesWithHiddenTreeMenu.includes(pageId)
      );
      const fullPages = this.productConfig.pagesWithFullWidthAndHeight;
      this.pageRequiresFullWidthAndHeight =
        fullPages && fullPages.includes(pageId);
    });
  }

  private updateDrawer(pageRequiresClosedTreeMenu: boolean = false) {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];
    if (pageRequiresClosedTreeMenu) {
      this.menuMode = 'overlap';
    } else {
      this.menuMode = isLarge ? 'shrink' : 'overlap';
    }
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.shaderEnabled = this.menuMode === 'overlap';
  }

  private setScrollbarAlwaysVisible() {
    dxTreeView.defaultOptions({
      options: {
        onInitialized: function (e) {
          e.component.on('contentReady', function (args) {
            const widget = Scrollable.getInstance(
              args.element.querySelector('.dx-scrollable')
            );
            widget.option('showScrollbar', 'always');
          });
        },
      },
    });
  }
}
