<div id="navigation-bar" class="bar">
  <domat-shell-button
    *ngIf="(treeNavService.treeNavOpened$ | async) === false"
    [@toggleTreeNavButton]
    class="tree-nav-toggle"
    iconName="menu"
    (clicked)="treeNavService.toggleTreeNav()"
  ></domat-shell-button>
  <domat-breadcrumbs
    class="breadcrumbs"
    [breadcrumbs]="breadcrumbs"
  ></domat-breadcrumbs>
</div>
