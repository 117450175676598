import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ContportFeatureShellModule } from '@domat/shell/feature-shell';
import {
  DocumentTitleService,
  DynamicConfigService,
  FeatureTogglesService,
  InitialConfigurationService,
  loadConfig,
  navigationServiceFactory,
  NAVIGATION_SERVICE,
  PermissionsService,
  RouteChangesListenerService,
  SharedDataAccessModule,
  StaticConfigService,
} from '@domat/shared/data-access';
import { SharedUiModule } from '@domat/shared/ui';
import {
  ENV,
  LocalizationConfigService,
  LoggingService,
  SharedUtilsModule,
  TimeZoneService,
} from '@domat/shared/utils';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule, // first import
    BrowserAnimationsModule,
    ContportFeatureShellModule,
    SharedDataAccessModule.forRoot(environment),
    SharedUiModule,
    SharedUtilsModule,
    HttpClientModule,
    TranslocoRootModule,
    AppRoutingModule, // last import
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'cs',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      multi: true,
      deps: [
        DynamicConfigService,
        InitialConfigurationService,
        TranslocoService,
        LocalizationConfigService,
        TimeZoneService,
        Router,
      ],
    },
    {
      provide: NAVIGATION_SERVICE,
      useFactory: navigationServiceFactory(false),
      deps: [
        Router,
        RouteChangesListenerService,
        DocumentTitleService,
        StaticConfigService,
        FeatureTogglesService,
        PermissionsService,
        LoggingService,
      ],
    },
    {
      provide: ENV,
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
