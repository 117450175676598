import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable, of, partition, timer } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  mapTo,
  share,
  startWith,
  takeUntil,
  tap,
  timeout,
} from 'rxjs/operators';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  constructor(private log: LoggingService) {}

  observableLoading<T>(
    request$: Observable<T>
  ): {
    data$: Observable<T>;
    error$: Observable<Error>;
    loading$: Observable<boolean>;
  } {
    const timeWithoutIndicator = 1000;
    const minimumIndicatorShowTime = 1000;
    const mainTimeout = 30000;

    const result$ = request$.pipe(
      map((data) => ({ success: true, payload: data })),
      tap((data) => {
        this.log.debug('observableLoading', `success: ${data.success}`);
      }),
      timeout(mainTimeout),
      catchError((error) => {
        this.log.error('observableLoading', error.message);
        return of({ success: false, payload: error });
      }),
      share()
    );

    const loading$: Observable<boolean> = merge(
      timer(timeWithoutIndicator).pipe(mapTo(true), takeUntil(result$)),
      combineLatest([
        result$,
        timer(timeWithoutIndicator + minimumIndicatorShowTime),
      ]).pipe(mapTo(false))
    ).pipe(startWith(false), distinctUntilChanged());

    const [data$, error$] = partition(
      result$,
      (result) => result.success
    ).map((observable) => observable.pipe(map((result) => result.payload)));

    return { data$, error$, loading$ };
  }
}
