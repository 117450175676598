import { Injectable } from '@angular/core';
import { UrlUtilsService } from '@domat/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  readonly spinnerIconUrl = this.urlUtils.asset('icons/oval.svg');
  readonly leafletMarkerIconUrl = this.urlUtils.asset(
    'leaflet/marker-icon-domat.png'
  );
  readonly leafletMarkerShadowUrl = this.urlUtils.asset(
    'leaflet/marker-shadow.png'
  );

  constructor(private urlUtils: UrlUtilsService) {}
}
