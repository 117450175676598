import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GuidService } from '@domat/shared/utils';
import { OperatorsService } from '../services/navigation-tree/operators.service';

@Injectable({
  providedIn: 'root',
})
export class DefaultTreeNodeGuard implements CanActivate {
  constructor(
    private router: Router,
    private operatorsService: OperatorsService,
    private guidService: GuidService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): boolean {
    this.router.navigate([
      next.routeConfig.path,
      this.guidService.convertOperatorGuidToTreeNodeId(
        this.operatorsService.getDefaultOperatorId()
      ),
    ]);
    return false;
  }
}
