import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'domat-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.css'],
})
export class GenericErrorComponent implements OnInit {
  error;

  constructor(private router: Router) {
    this.error = this.router.getCurrentNavigation().extras.state?.error;
  }

  ngOnInit(): void {
    window.history.replaceState(undefined, undefined, '/');
  }
}
