import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { TreeNode } from '@domat/shared/data-access';
import { TreeNavigationUiService } from '@domat/shared/utils';

@Component({
  selector: 'domat-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css'],
  animations: [
    trigger('toggleTreeNavButton', [
      transition(':enter', [
        style({ width: 0, opacity: 0.1 }),
        animate('230ms 50ms', style({ width: '2.4rem', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ width: '2.4rem', opacity: 1 }),
        animate('230ms 50ms', style({ width: 0, opacity: 0.1 })),
      ]),
    ]),
  ],
})
export class NavigationBarComponent implements OnInit {
  @Input() breadcrumbs: TreeNode[];

  constructor(public treeNavService: TreeNavigationUiService) {}

  ngOnInit() {}
}
