<body>
  <div *ngIf="popupHeaderImageSrc !== ''" id="header">
    <img class="logo" [src]="popupHeaderImageSrc" [alt]="popupHeaderImageSrc" />
  </div>

  <div class="container">
    <div id="sidebar">
      <ul>
        <li (click)="general()" data-cy="application-info-general">Obecně</li>
        <li (click)="thirdParty()" data-cy="application-info-third-party">
          Licence třetích stran
        </li>
        <li
          data-cy="application-info-licence-agreement"
          *ngIf="staticConfig.productConfig.licenceAgreement"
          (click)="licenceAgreement()"
        >
          Obchodní podmínky
        </li>
        <li></li>
      </ul>
    </div>
    <div id="main" [ngSwitch]="content" data-cy="application-info-main">
      <div *ngSwitchCase="'general'">
        <domat-general-info [popupContentImageSrc]="popupContentImageSrc">
        </domat-general-info>
      </div>
      <div *ngSwitchCase="'thirdparty'">
        <domat-third-party-licences></domat-third-party-licences>
      </div>
      <div *ngSwitchCase="'licenceagreement'">
        <domat-licence-agreement></domat-licence-agreement>
      </div>
    </div>
  </div>
</body>
