import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppPage } from '../../model/AppPage';
import { NavigationServiceInterface } from './NavigationServiceInterface';

@Injectable({
  providedIn: 'root',
})
export class NavigationServiceMock implements NavigationServiceInterface {
  currentTreeNodeId$ = of('baz');
  currentTreeNodeData$ = of({
    id: 'bar',
    urowid: 'bar',
    ntype: 'PRO',
    caption: 'Bar',
    purowid: 'foo',
    hasItems: false,
  });
  currentSecondaryPageId$ = of('foo');
  currentSecondaryPageData$ = of({
    id: 'foo',
    title: 'Foo',
    queryParams: ['foo'],
    icon: 'plus',
    role: null,
  });
  currentPrimaryPageId$ = of('information');
  currentPrimaryPageData$ = of({
    id: 'information',
    title: 'Informace',
    queryParams: ['information'],
    icon: 'about',
    role: null,
  });
  currentQueryParams$: Observable<Params>;

  constructor() {}

  keepAppTitleUpdated(): void {}

  getPrimaryNavigationItems() {
    return [
      {
        id: 'information',
        title: 'Informace',
        queryParams: '',
        icon: 'about',
        role: null,
      },
      {
        id: 'online-data',
        title: 'Online-data',
        queryParams: '',
        icon: 'onlineData',
        role: null,
      },
      {
        id: 'service-workflow',
        title: 'Služby',
        queryParams: '',
        icon: 'services',
        role: null,
      },
      {
        id: 'reports',
        title: 'Reports',
        queryParams: '',
        icon: 'reports',
        role: null,
      },
      { id: 'mock', title: 'Mock', queryParams: '', icon: 'plus', role: null },
    ];
  }

  getSecondaryNavigationItems(): AppPage[] {
    return [
      { id: 'foo', title: 'Foo', queryParams: '', icon: 'plus', role: null },
      { id: 'bar', title: 'Bar', queryParams: '', icon: 'minus', role: null },
    ];
  }

  setNextTreeNodeData(): void {
    //
  }
}
