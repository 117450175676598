<meta name="viewport" content="width=device-width, initial-scale=1" />
<div class="container">
  <table id="licences">
    <tr *ngFor="let licence of licences">
      <td class="name">{{ licence.name }}</td>
      <td class="type">{{ licence.type }}</td>
      <td class="link">
        <a
          *ngIf="licence.link !== ''"
          href="{{ licence.link }}"
          target="_blank"
        >
          <domat-icon name="arrowTopLeft"></domat-icon
        ></a>
      </td>
    </tr>
  </table>
</div>
