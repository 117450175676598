import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'domat-licence-agreement',
  templateUrl: './licence-agreement.component.html',
  styleUrls: ['./licence-agreement.component.css'],
})
export class LicenceAgreementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
