import { Injectable } from '@angular/core';
import { loadMessages, locale } from 'devextreme/localization';
import csMessages from 'devextreme/localization/messages/cs.json';
import enMessages from 'devextreme/localization/messages/en.json';

@Injectable({
  providedIn: 'root',
})
export class LocalizationConfigService {
  constructor() {}

  configureDevextreme(language: string) {
    if (language.startsWith('cs')) {
      loadMessages(csMessages);
    }
    if (language.startsWith('en')) {
      loadMessages(enMessages);
    }
    locale(language);
  }
}
