import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'domat-product-logo',
  template: `
    <img *ngIf="src" class="logo u-no-select" alt="Product logo" [src]="src" />
  `,
  styles: [
    `
      .logo {
        grid-area: merbon-logo;
        justify-self: flex-end;
        padding: var(--spacing-unit-small);
        height: 2.2rem;
      }

      @media (max-width: 700px) {
        .logo {
          justify-self: flex-start;
          padding: var(--spacing-unit-tiny);
          height: 100%;
        }
      }
    `,
  ],
})
export class ProductLogoComponent implements OnInit {
  @Input() src: string;

  constructor() {}

  ngOnInit() {}
}
