import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { defaultRoutes } from '../../../routing';
import {
  GenericErrorComponent,
  NotEnoughPermissionsComponent,
} from '@domat/shared/ui';
import { ShellComponent } from '@domat/shell/feature-shell';

const routes: Routes = [
  {
    path: '',
    component: ShellComponent,
    canActivateChild: [],
    children: [
      ...defaultRoutes,
      {
        path: 'not-enough-permissions',
        component: NotEnoughPermissionsComponent,
        data: {
          title: 'Nedostatečná práva',
        },
      },
      {
        path: '',
        redirectTo: '/information',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'error',
    component: GenericErrorComponent,
    data: {
      title: 'Error',
    },
  },
  // {
  //   path: 'login',
  //   component: LoginComponent,
  //   data: {
  //     title: 'Login',
  //   },
  // },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
