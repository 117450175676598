import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconsService } from '../../services/icons.service';

@Component({
  selector: 'domat-form-button',
  templateUrl: './form-button.component.html',
  styleUrls: ['./form-button.component.css'],
})
export class FormButtonComponent {
  @Input() text: string = '';
  @Input() hint: string = '';
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;

  @Output() clicked = new EventEmitter();

  indicatorSrc: string;

  constructor(private iconsService: IconsService) {
    this.indicatorSrc = this.iconsService.spinnerIconUrl;
  }

  onClick() {
    if (!this.loading) {
      this.clicked.emit();
    }
  }
}
