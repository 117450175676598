import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Params } from '@angular/router';
import {
  AppPage,
  AppRoles,
  PermissionsService,
} from '@domat/shared/data-access';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'domat-secondary-navigation',
  templateUrl: './secondary-navigation.component.html',
  styleUrls: ['./secondary-navigation.component.css'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'shell',
    },
  ],
})
export class SecondaryNavigationComponent implements OnInit, OnChanges {
  @Input() pages: AppPage[];
  @Input() currentPrimaryPageId: string;
  @Input() currentTreeNodeId: string;
  @Input() currentSecondaryPageId: string;
  @Input() currentQueryParams: Params;

  canEditOnlineData: boolean;

  constructor(private permissions: PermissionsService) {
    this.canEditOnlineData = this.permissions.checkRole(
      AppRoles.onlineDataEditor
    );
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pages) {
      if (!this.canEditOnlineData) {
        this.pages = this.pages.filter((x) => x.id !== 'import');
      }
    }
  }
}
