import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import { LoggingService } from '@domat/shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppPage } from '../../model/AppPage';
import { TreeNode } from '../../model/TreeNode';
import { FeatureTogglesService } from '../feature-toggles.service';
import { resolveIconFromNodeType } from '../navigation-tree/resolveIconFromNodeType';
import { PermissionsService } from '../permissions.service';
import { StaticConfigService } from '../static-config.service';
import { DocumentTitleService } from './document-title.service';
import { NavigationServiceInterface } from './NavigationServiceInterface';
import { RouteChangesListenerService } from './route-changes-listener.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements NavigationServiceInterface {
  private currentTreeNodeData = new BehaviorSubject<TreeNode>({});
  private currentTreeNodeId = new BehaviorSubject<string>('');
  private currentPrimaryPageId = new BehaviorSubject<string>('');
  private currentPrimaryPageData = new BehaviorSubject<AppPage>(null);
  private currentSecondaryPageId = new BehaviorSubject<string>('');
  private currentSecondaryPageData = new BehaviorSubject<AppPage>(null);
  private currentQueryParams = new BehaviorSubject<any>('');

  /* eslint-disable @typescript-eslint/member-ordering */
  currentTreeNodeId$: Observable<string> =
    this.currentTreeNodeId.asObservable();
  currentTreeNodeData$: Observable<TreeNode> =
    this.currentTreeNodeData.asObservable();

  currentPrimaryPageId$: Observable<string> =
    this.currentPrimaryPageId.asObservable();
  currentPrimaryPageData$: Observable<AppPage> =
    this.currentPrimaryPageData.asObservable();

  currentSecondaryPageId$: Observable<string> =
    this.currentSecondaryPageId.asObservable();
  currentSecondaryPageData$: Observable<AppPage> =
    this.currentSecondaryPageData.asObservable();
  currentQueryParams$: Observable<any> = this.currentQueryParams.asObservable();
  /* eslint-enable @typescript-eslint/member-ordering */

  constructor(
    private router: Router,
    private routeChanges: RouteChangesListenerService,
    private appTitleService: DocumentTitleService,
    private staticConfig: StaticConfigService,
    private featureToggles: FeatureTogglesService,
    private permissions: PermissionsService,
    private log: LoggingService
  ) {
    routeChanges.primaryPageParamsChanges$
      .pipe(map((params) => params.treeNodeId))
      .subscribe((value) => {
        this.log.debug(
          'NavigationService',
          `primaryPageParamsChanges: ${value}`
        );
        this.currentTreeNodeId.next(value);
      });
    routeChanges.primaryPageChanges$
      .pipe(map((page) => page.id))
      .subscribe((value) => {
        this.log.debug('NavigationService', `primaryPageChanges: ${value}`);
        this.currentPrimaryPageId.next(value);
      });
    routeChanges.secondaryPageChanges$
      .pipe(map((page) => page.id))
      .subscribe((value) => {
        this.log.debug('NavigationService', `secondaryPageChanges: ${value}`);
        this.currentSecondaryPageId.next(value);
      });
    routeChanges.primaryPageChanges$.subscribe((pageData) => {
      this.currentPrimaryPageData.next(pageData);
    });
    routeChanges.secondaryPageChanges$.subscribe((pageData) => {
      this.currentSecondaryPageData.next(pageData);
    });
    routeChanges.queryParamsChanges$.subscribe((params) => {
      this.currentQueryParams.next(params);
    });
  }

  public keepAppTitleUpdated() {
    this.routeChanges.leafChanges$.subscribe((routeData) => {
      this.appTitleService.setTitle(
        this.staticConfig.productConfig.productName,
        routeData.title,
        ''
      );
    });
  }

  getPrimaryNavigationItems(): AppPage[] {
    return this.router.config[0].children
      .filter((route) => route.data && route.data.id)
      .filter((route) => this.permissions.checkRole(route.data.role))
      .map((route) => route.data as AppPage);
  }

  getSecondaryNavigationItems(primaryPageId: string): AppPage[] {
    const primaryPageConfig: any = this.router.config[0].children.find(
      (route) => route.path === primaryPageId
    );

    const routes = primaryPageConfig._loadedRoutes[0].children;
    if (!routes) {
      return [];
    }

    return routes
      .filter((route) => route.data && route.data.id)
      .filter((route) =>
        route.data.role ? this.permissions.checkRole(route.data.role) : true
      )
      .map((route) => route.data as AppPage);
  }

  setNextTreeNodeData(treeNode: TreeNode): void {
    treeNode.icon = resolveIconFromNodeType(treeNode.ntype);
    this.currentTreeNodeData.next(treeNode);
  }
}
