import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DxInputAttributesService {
  constructor() {}

  addCypressAttributes(form: HTMLElement, customName?: string): HTMLElement {
    Array.from(form.querySelectorAll('input, textarea')).forEach((item) => {
      item.setAttribute(
        'data-cy',
        this.getInputNameFromId(item.id) !== ''
          ? this.getInputNameFromId(item.id)
          : customName
      );
    });
    return form;
  }

  private getInputNameFromId(id: string): string {
    const array: string[] = id.split('_');
    return array[array.length - 1];
  }
}
