import { Injectable } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { EndpointsService } from '@domat/shared/data-access';

interface ODataStoreRequestParams {
  $filter?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TreeNavigationDataSourceService {
  constructor(private endpoints: EndpointsService) {}

  getDataSource() {
    return new DataSource({
      store: new ODataStore({
        url: this.endpoints.navigationTreeUrl(),
        version: 4,
        key: 'urowid',
        keyType: 'String',
        withCredentials: true,
        fieldTypes: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          parent_urowid: 'String',
        },

        beforeSend: (req) => {
          // Smyslem je predelat default filtr tak, aby bylo aplikovano case-insensitive vyhledavani
          const filter = (req.params as ODataStoreRequestParams).$filter;

          // $filter je napr. (contains(tolower(caption),'test'))
          // https://regex101.com/r/vdBGMz/2
          const filterRegex = /^\(contains\(tolower\(caption\),('.*?')\)\)$/g;

          const matches = filterRegex.exec(filter);
          if (filter != null && matches !== null) {
            (req.params as ODataStoreRequestParams).$filter = filter.replace(
              matches[1],
              ` tolower(${matches[1]})`
            );
          }
        },
      }),
    });
  }
}
