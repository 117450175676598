import { Component, Input, OnInit } from '@angular/core';
import { InitialConfiguration, ProductConfig } from '@domat/shared/data-access';
import { UrlUtilsService } from '@domat/shared/utils';

@Component({
  selector: 'domat-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.css'],
})
export class MainHeaderComponent implements OnInit {
  @Input() productConfig: ProductConfig;
  @Input() initialConfig: InitialConfiguration;

  companyName;
  companyLogoSrc;
  productLogoSrc;

  constructor(private urlUtils: UrlUtilsService) {}

  ngOnInit() {
    this.companyName = this.productConfig.companyName;
    this.companyLogoSrc = this.urlUtils.asset(
      this.productConfig.companyLogoSrc
    );
    this.productLogoSrc = this.urlUtils.asset(
      this.productConfig.productLogoSrc
    );
  }
}
