import { Routes } from '@angular/router';
import { AppRoles, DefaultTreeNodeGuard } from '@domat/shared/data-access';

const navScope = 'shell.primaryNavigation.';

export const defaultRoutes: Routes = [
  {
    path: 'information',
    canActivate: [DefaultTreeNodeGuard],
    loadChildren: () =>
      import('@domat/contport/feature-information').then(
        (m) => m.ContportFeatureInformationModule
      ),
  },
  {
    path: 'information/:treeNodeId',
    loadChildren: () =>
      import('@domat/contport/feature-information').then(
        (m) => m.ContportFeatureInformationModule
      ),
    data: {
      id: 'information',
      title: 'Informace',
      translocoId: navScope + 'information',
      icon: 'about',
    },
  },
  {
    path: 'online-data',
    canActivate: [DefaultTreeNodeGuard],
    loadChildren: () =>
      import('@domat/contport/feature-online-data').then(
        (m) => m.ContportFeatureOnlineDataModule
      ),
  },
  {
    path: 'online-data/:treeNodeId',
    loadChildren: () =>
      import('@domat/contport/feature-online-data').then(
        (m) => m.ContportFeatureOnlineDataModule
      ),
    data: {
      id: 'online-data',
      title: 'On-line data',
      translocoId: navScope + 'onlineData',
      icon: 'onlineData',
    },
  },
  {
    path: 'service-workflow',
    canActivate: [DefaultTreeNodeGuard],
    loadChildren: () =>
      import('@domat/contport/feature-service-workflow').then(
        (m) => m.ContportFeatureServiceWorkflowModule
      ),
  },
  {
    path: 'service-workflow/:treeNodeId',
    loadChildren: () =>
      import('@domat/contport/feature-service-workflow').then(
        (m) => m.ContportFeatureServiceWorkflowModule
      ),
    data: {
      id: 'service-workflow',
      title: 'Služby',
      translocoId: navScope + 'serviceWorkflow',
      icon: 'services',
      role: AppRoles.ticketViewer,
    },
  },
  {
    path: 'reports',
    canActivate: [DefaultTreeNodeGuard],
    loadChildren: () =>
      import('@domat/contport/feature-reports').then(
        (m) => m.ContportFeatureReportsModule
      ),
  },
  {
    path: 'reports/:treeNodeId',
    loadChildren: () =>
      import('@domat/contport/feature-reports').then(
        (m) => m.ContportFeatureReportsModule
      ),
    data: {
      id: 'reports',
      title: 'Reporty',
      translocoId: navScope + 'reports',
      icon: 'reports',
      role: AppRoles.reportViewerName,
    },
  },
  {
    path: 'user-settings',
    canActivate: [DefaultTreeNodeGuard],

    loadChildren: () =>
      import('@domat/contport/feature-settings').then(
        (m) => m.ContportFeatureSettingsModule
      ),
  },
  {
    path: 'user-settings/:treeNodeId',

    loadChildren: () =>
      import('@domat/contport/feature-settings').then(
        (m) => m.ContportFeatureSettingsModule
      ),
    data: {
      id: 'user-settings',
      title: 'Nastavení',
      icon: 'settings',
    },
  },
];

export const contportRoutes = defaultRoutes;
