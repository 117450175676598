import { Injectable } from '@angular/core';
import { TimeZone, getTimeZones } from '@vvo/tzdb';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneService {
  selectedTimezone: TimeZone;
  currentTimezoneOffset: string;
  timeZones = getTimeZones();

  constructor() {}

  setCurrentTimeZone(timezoneName: string) {
    this.selectedTimezone = this.getTimeZoneFromName(timezoneName);
    if (this.selectedTimezone.currentTimeOffsetInMinutes > 0) {
      this.currentTimezoneOffset =
        'UTC+' +
        (this.selectedTimezone.currentTimeOffsetInMinutes / 60).toString();
    } else {
      this.currentTimezoneOffset =
        'UTC' +
        (this.selectedTimezone.currentTimeOffsetInMinutes / 60).toString();
    }
  }

  getTimeZoneFromName(name: string) {
    return this.timeZones.find((item) => item.name === name);
  }

  getCurrentTimezoneOffset() {
    return this.currentTimezoneOffset;
  }

  getSelectedTimeZone() {
    return this.selectedTimezone ?? null;
  }
}
