<div class="header"></div>

<div class="info">
  <span class="provider">Poskytovatel:</span>
  <div>
    <span>Domat Control System, s.r.o.</span><br />
    <span>U Panasonicu 378, Pardubice,</span><br />
    <span>
      zapsaná v obchodním rejstříku vedeném Městským soudem v Praze v oddílu C,
      vložka 103100 </span
    ><br />
    <span>IČO: 271 89 465, DIČ: CZ 271 89 465</span>
  </div>
</div>

<div class="content">
  <div class="section">
    <h2 class="subheading">I. Předmět licence</h2>
    <p class="paragraph">
      Předmětem této smlouvy je poskytnutí práv k užívání počítačového programu
      <span class="highlighted">Merbon ContPort</span>, dále jen SOFTWARE, pro
      sběr a vyhodnocování dat v Projektu. SOFTWARE je autorským dílem chráněným
      ustanoveními autorského zákona č. 121/2000 Sb.. Podle této smlouvy
      <span class="highlighted">Poskytovatel</span> poskytuje právo užívat
      SOFTWARE <span class="highlighted">Nabyvateli</span> po dobu platnosti
      této smlouvy podle dále uvedených ustanovení.
    </p>
  </div>

  <div class="section">
    <h2 class="subheading">II. Licencování</h2>
    <p class="paragraph">
      <span class="highlighted">Poskytovatel</span> v rámci implementace
      poskytuje k SOFTWARE kompletní instalaci a nakonfigurování datových bodů
      včetně kalkulačních a prezentačních funkcí, které jsou specifikovány ve
      Smlouvě o spolupráci a zpřístupnění informací v systému ContPort. Součástí
      implementace je omezení na počet datových bodů, na něž je licence vydána,
      a licencované funkce. Licence platí pro jeden server.
    </p>
    <p class="paragraph">
      Části SOFTWARE mohou být Open Source. Tyto části mohou být používány v
      souladu s příslušnými Open Source licencemi, které mají přednost před
      tímto Ujednáním.
    </p>
  </div>

  <div class="section">
    <h2 class="subheading">III. Oprávnění nabyvatele</h2>
    <li class="paragraph">
      <span class="highlighted">Nabyvatel</span> je oprávněn provozovat SOFTWARE
      tak dlouho, dokud bude respektovat podmínky této smlouvy, a to ve spojení
      s daty, která jsou nakonfigurována v
      <span class="highlighted">Projektu</span>.
    </li>
    <li class="paragraph">
      <span class="highlighted">Nabyvatel</span> má právo na dodávku nových
      verze SOFTWARE (upgrade) zdarma po dobu záruky. Podmínky instalace se řídí
      Smlouvou o spolupráci a zpřístupnění informací v systému ContPort. Práce,
      jako instalace, konfigurace systému, zálohování apod. nejsou součástí
      tohoto ujednání a mohou být poskytovány za úplatu.
    </li>
    <li class="paragraph">
      <span class="highlighted">Nabyvatel</span> je oprávněn pořídit si
      rozmnoženiny SOFTWARE, pokud jej potřebuje pro vlastní provoz programu
      podle bodu a) uvedeného výše za účelem vykonávání funkcí programu (např.
      pro zrcadlení disků), a dále pro archivní účely, popřípadě k nahrazení
      oprávněně získané rozmnoženiny.
    </li>
  </div>

  <div class="section">
    <h2 class="subheading">IV. Nabyvatel není oprávněn:</h2>
    <li class="paragraph">instalovat SOFTWARE na více serverů současně,</li>
    <li class="paragraph">
      pořizovat rozmnoženiny SOFTWARE pro jiné účely, než stanoví toto ujednání,
    </li>
    <li class="paragraph">
      jakýmkoliv způsobem SOFTWARE analyzovat, měnit, překládat jej do jiných
      programovacích či národních jazyků nebo do zdrojového tvaru nebo do tvaru
      assembler, zahrnout jej do jiného software a šířit produkty takto vzniklé,
      odvozené od původního SOFTWARE a jakkoliv jinak zasahovat do vnitřní
      struktury SOFTWARE, s výjimkou případů výslovně povolených autorským
      zákonem,
    </li>
    <li class="paragraph">
      půjčovat, pronajímat, převádět získaná oprávnění či jiným nepovoleným
      způsobem nakládat se SOFTWARE.
    </li>
    <li class="paragraph">
      používat SOFTWARE, pokud
      <span class="highlighted">poskytovatel</span> neobržel za něj úhradu.
    </li>
  </div>

  <div class="section">
    <h2 class="subheading">V. Odpovědnost poskytovatele</h2>
    <p class="paragraph">
      <span class="highlighted">Poskytovatel</span> poskytuje dvouletou záruku
      na provoz SOFTWARE v souladu se Smlouvou o spolupráci a zpřístupnění
      informací v systému ContPort. Odpovědnosti za vady se
      <span class="highlighted">poskytovatel</span> může zbavit bezplatnou
      instalací opravené verze na server.
      <span class="highlighted">Poskytovatel</span> neodpovídá za škody vzniklé
      nesprávným provozováním SOFTWARE v rozporu s podmínkami, uvedenými v
      uživatelské dokumentaci. V případě jakékoliv škody vzniklé uživateli nebo
      třetí osobě přímo nebo nepřímo na základě používání softwarového produktu,
      je případná odpovědnost <span class="highlighted">poskytovatele</span> za
      tuto škodu výslovně podmíněná jeho zaviněním, a případná výše náhrady
      škody v žádném případě nepřesáhne smluvní pokutu ve výši jedné pravidelné
      úhrady za poskytnutí SOFTWARE ve smyslu Smlouvy o spolupráci a
      zpřístupnění informací v systému ContPort.
    </p>
  </div>

  <div class="section">
    <h2 class="subheading">VI. Doba platnosti Ujednání</h2>
    <p class="paragraph">
      Ujednání zůstává v platnosti, pokud
      <span class="highlighted">nabyvatel</span> neporuší smluvní podmínky.
      Platnosti pozbývá bez dalšího okamžikem, kdy
      <span class="highlighted">nabyvatel</span> učiní cokoliv v rozporu s
      ustanoveními Ujednání. V okamžiku ukončení platnosti Ujednání je
      <span class="highlighted">nabyvatel</span> povinen přestat SOFTWARE dále
      jakkoliv používat.
    </p>
  </div>

  <div class="section">
    <h2 class="subheading">VII. Uzavření smlouvy</h2>

    <p class="paragraph">
      Toto Ujednání je uzavřeno ze strany
      <span class="highlighted">poskytovatele</span> zpřístupněním SOFTWARE
      <span class="highlighted">nabyvateli</span>, ze strany
      <span class="highlighted">nabyvatele</span> okamžikem, kdy
      <span class="highlighted">nabyvatel</span> zahájí používání
      nainstalovaného a nakonfigurovaného SOFTWARE. Tento úkon
      <span class="highlighted">nabyvatele</span> se pokládá za akceptaci návrhu
      <span class="highlighted">Poskytovatele</span>. Od tohoto okamžiku také
      začíná běžet záruka.
    </p>
  </div>
</div>
