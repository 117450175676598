import { Inject, Injectable } from '@angular/core';
import {
  ApplicationError,
  ErrorMessagesService,
  LoggingService,
} from '@domat/shared/utils';
import { combineLatest, Observable, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

import { NAVIGATION_SERVICE } from './navigation/navigation-service.factory';
import { NavigationServiceInterface } from './navigation/NavigationServiceInterface';

export interface AlertExternal {
  type: AlertType;
  message: string | ApplicationError;
}

export interface AlertInternal {
  type: AlertType;
  message: string[];
  created: Date;
  location: string;
  duration: number;
}

export enum AlertType {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  currentPrimaryPageId: string;
  private alerts = new Subject<AlertInternal>();
  private location: string;

  private readonly infoTimeout = 4000;
  private readonly errorTimeout = 15000;

  constructor(
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface,
    private errorMessagesService: ErrorMessagesService,
    private log: LoggingService
  ) {
    combineLatest([
      this.navigation.currentPrimaryPageData$,
      this.navigation.currentSecondaryPageData$,
    ]).subscribe(([primaryPageData, secondaryPageData]) => {
      let location = primaryPageData && `${primaryPageData.title}`;
      if (location && secondaryPageData) {
        location += ` @ ${secondaryPageData.title}`;
      }
      this.location = location ?? '';
    });
  }

  success(message: string, durationMilliseconds: number = this.infoTimeout) {
    this.alert({ message, type: AlertType.success }, durationMilliseconds);
  }

  error(
    message: ApplicationError,
    durationMilliseconds: number = this.errorTimeout
  ) {
    this.alert({ message, type: AlertType.error }, durationMilliseconds);
  }

  info(message: string, durationMilliseconds: number = this.infoTimeout) {
    this.alert({ message, type: AlertType.info }, durationMilliseconds);
  }

  warning(message: string, durationMilliseconds: number = this.infoTimeout) {
    this.alert({ message, type: AlertType.warning }, durationMilliseconds);
  }

  getAlerts(): Observable<AlertInternal | null> {
    return this.alerts.asObservable().pipe(share());
  }

  clearAlerts() {
    this.alerts.next(null);
  }

  private alert(alert: AlertExternal, duration: number) {
    const alertInternal: AlertInternal = {
      type: alert.type,
      message:
        typeof alert.message === 'string'
          ? [alert.message]
          : this.errorMessagesService.parseErrorMessage(alert.message),
      created: new Date(),
      location: this.location,
      duration: duration,
    };
    if (alert.type === AlertType.error) {
      this.log.error(this.location, alertInternal.message?.toString());
    } else {
      this.log.debug(
        this.location,
        `[${alertInternal.type}] ${alertInternal.message?.toString()}`
      );
    }
    this.alerts.next(alertInternal);
  }
}
