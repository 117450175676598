import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  distinctUntilKeyChanged,
  filter,
  map,
  switchMap,
  share,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AppPage } from '../../model/AppPage';

@Injectable({
  providedIn: 'root',
})
export class RouteChangesListenerService {
  private routeChanges$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.activatedRoute),
    filter((route) => route.outlet === 'primary'),
    share()
  );

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  get secondaryPageChanges$(): Observable<AppPage> {
    return this.routeChanges$.pipe(
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(
        (route) =>
          route.snapshot.parent != null && route.snapshot.parent.parent != null
      ),
      switchMap((route) => route.data),
      map((data) => data as AppPage),
      distinctUntilKeyChanged('id')
    );
  }

  get leafChanges$(): Observable<any> {
    return this.routeChanges$.pipe(
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      switchMap((route) => route.data),
      map((data) => data as AppPage),
      distinctUntilKeyChanged('id')
    );
  }

  get primaryPageChanges$(): Observable<AppPage> {
    return this.routeChanges$.pipe(
      map((route) => route.firstChild.firstChild || route.firstChild),
      switchMap((route) => route.data),
      map((data) => data as AppPage),
      distinctUntilKeyChanged('id')
    );
  }

  get primaryPageParamsChanges$(): Observable<{ treeNodeId: string }> {
    return this.routeChanges$.pipe(
      map((route) => route.firstChild.firstChild || route.firstChild),
      switchMap((route) => route.params),
      map((params) => params as { treeNodeId: string }),
      distinctUntilKeyChanged('treeNodeId')
    );
  }

  get queryParamsChanges$() {
    return this.routeChanges$.pipe(
      switchMap((route) => route.firstChild.queryParams),
      distinctUntilChanged()
    );
  }
}
