import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'domat-not-enough-permissions',
  templateUrl: './not-enough-permissions.component.html',
  styleUrls: ['./not-enough-permissions.component.css'],
})
export class NotEnoughPermissionsComponent implements OnInit {
  reason: string;
  params$: Observable<any>;

  constructor(private route: ActivatedRoute, private location: Location) {}

  ngOnInit() {
    this.params$ = this.route.queryParams;
  }

  back() {
    this.location.back();
    this.location.back();
  }
}
