import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'domat-shell-button',
  templateUrl: './shell-button.component.html',
  styleUrls: ['./shell-button.component.css'],
})
export class ShellButtonComponent implements OnInit {
  @Input() active: boolean;
  @Input() iconName: string;
  @Input() text: string;
  @Input() tooltip: string;

  @Output() clicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
