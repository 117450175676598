import { Injectable } from '@angular/core';
import { DynamicConfigService } from './dynamic-config.service';

@Injectable({ providedIn: 'root' })
export class FeatureTogglesService {
  constructor(private config: DynamicConfigService) {}

  /**
   * Defaultne je vse vypnuto. Jen v pripade pritomnosti daneho flagu
   * a jeho hodnote true je featura zapnuta.
   */
  isEnabled(feature: string): boolean {
    if (this.config.appConfig.features) {
      switch (feature) {
        case 'Help':
          if (this.config.appConfig.features.featureHelp === true) {
            return true;
          }
          break;
        case 'NewOnlineData':
          if (this.config.appConfig.features.featureNewOnlineData === true) {
            return true;
          }
          return false;
          break;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
