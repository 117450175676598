import { Inject, Injectable } from '@angular/core';
import { ENV, Environment } from './env';

@Injectable({
  providedIn: 'root',
})
export class UrlUtilsService {
  constructor(@Inject(ENV) private env: Environment) {}

  /**
   * Vytvori absolutni cestu k souboru (assetu).
   * Zohledni cestu pro nasazeni aplikace.
   * Vrati prazdny retezec, pokud je vstupni url prazdna.
   * @param url relativni cesta k souboru
   */
  asset(url: string): string {
    if (url) {
      const deployUrl = this.removeSlash(this.env.deployUrl);
      const assetsDirectoryName = 'assets';
      const assetUrl = this.removeSlash(url);
      return (
        '/' +
        [deployUrl, assetsDirectoryName, assetUrl]
          .filter((part) => part !== null && part !== undefined && part !== '')
          .join('/')
      );
    } else {
      return '';
    }
  }

  convertToHtmlLinks(text: string): string {
    const urlRegex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    text = text.replace(urlRegex, function (url) {
      return (
        '<a class="o-link--on-light" href="' +
        url +
        '" target="_blank">' +
        url +
        '</a>'
      );
    });
    return text;
  }

  isValidURL(str) {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[A-Za-z0-9_áčďéěíňóřšťůúýžÁČĎÉĚÍŇÓŘŠŤŮÚÝŽ?&%=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }

  private removeSlash(str: string) {
    let result = str;
    if (str.charAt(0) === '/') {
      result = str.substring(1);
    }
    if (str.charAt(str.length - 1) === '/') {
      result = str.substring(0, str.length - 1);
    }
    return result;
  }
}
