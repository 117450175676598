import { Inject, Injectable } from '@angular/core';
import { LoggingService } from '@domat/shared/utils';
import ODataStore from 'devextreme/data/odata/store';
import { BehaviorSubject, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
  ProjectTaskCountResponse,
  ServiceProjectService,
} from '../../api-client.generated';
import { TreeNode } from '../../model/TreeNode';
import { EndpointsService } from '../endpoints.service';
import { NAVIGATION_SERVICE } from '../navigation/navigation-service.factory';
import { NavigationServiceInterface } from '../navigation/NavigationServiceInterface';
import { resolveIconFromNodeType } from './resolveIconFromNodeType';

interface TreeNodesDictionary {
  [id: string]: TreeNode;
}

let _state: TreeNodesDictionary = {};

@Injectable({
  providedIn: 'root',
})
export class NavigationTreeStoreService {
  odataStore: ODataStore;

  private store = new BehaviorSubject<TreeNodesDictionary>(_state);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  state$ = this.store.asObservable();

  constructor(
    private serviceProject: ServiceProjectService,
    private log: LoggingService,
    @Inject(NAVIGATION_SERVICE) navigation: NavigationServiceInterface,
    private endpoints: EndpointsService
  ) {
    this.odataStore = new ODataStore({
      url: this.endpoints.navigationTreeUrl(),
      version: 4,
      key: 'urowid',
      keyType: 'String',

      withCredentials: true,

      onLoaded: (result: TreeNode[]) => {
        result.forEach((treeNode) => {
          treeNode.id = treeNode.urowid;
          treeNode.parent = treeNode.purowid;
          treeNode.icon = resolveIconFromNodeType(treeNode.ntype);
          treeNode.isSelected = false;

          navigation.currentPrimaryPageId$.subscribe(
            (id) => {
              if (id === 'service-workflow') {
                this.loadTicketCounts(treeNode);
              }
            },
            () => {},
            () => {
              this.store.next(
                (_state = { ..._state, [treeNode.id]: treeNode })
              );
            }
          );
        });
      },
    });
  }

  private loadTicketCounts(treeNode: Partial<TreeNode>) {
    this.serviceProject
      .getTaskCount(treeNode.id)
      .pipe(
        catchError(() => {
          this.log.info(
            'NavigationTreeStoreService',
            `Nepodarilo se nacist pocty tiketu pro uzel stromu ${treeNode.id}, nejspis neexistuje prislusny projekt v Redmine`
          );
          return of({
            openCount: null,
            openCountPastDueDate: null,
          });
        })
      )
      .subscribe((ticketsCount: ProjectTaskCountResponse) => {
        treeNode.totalTickets = ticketsCount.totalTaskCount;
        treeNode.dueTickets = ticketsCount.overdueTaskCount;
      });
  }
}
