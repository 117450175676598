import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertInternal,
  AlertService,
  AlertType,
} from '@domat/shared/data-access';

@Component({
  selector: 'domat-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.css'],
  animations: [
    trigger('reveal', [
      transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class GlobalAlertComponent implements OnInit {
  alerts: AlertInternal[] = [];
  totalNumberOfLines = 0;

  private readonly lineLimit = 20;
  private readonly alertsLimit = 5;

  constructor(private alertService: AlertService, private router: Router) {}

  ngOnInit() {
    this.alertService.getAlerts().subscribe((alert) => {
      if (!alert) {
        // clear all alerts when empty alert arrives
        this.alerts = [];
        this.totalNumberOfLines = 0;
        return;
      }

      if (this.tooMuch()) {
        this.alerts.shift();
      }
      this.alerts.push(alert);
      // schedule alert removal
      if (alert.duration > 0) {
        setTimeout(() => this.close(alert), alert.duration);
      }
    });
  }

  close(alertToClose: AlertInternal) {
    this.alerts = this.alerts.filter((alert) => alert !== alertToClose);
  }

  private tooMuch() {
    if (this.alerts.length > this.alertsLimit) {
      return true;
    }

    let lines = 0;
    for (const alert of this.alerts) {
      if (Array.isArray(alert.message)) {
        // alert component does not display more then 5 lines
        lines += Math.min(alert.message.length, 5);
      } else {
        lines += 1;
      }
    }
    if (lines > this.lineLimit) {
      return true;
    }

    return false;
  }
}
