import { Injectable } from '@angular/core';
import { ApplicationError } from '../model/ApplicationError';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesService {
  constructor() {}

  swaggerExceptionToMessages(exception: {
    message?;
    status?;
    result?;
    error?;
  }): string[] {
    if (!exception || (Array.isArray(exception) && exception.length === 0)) {
      return [];
    }
    let { message, status, result } = exception;
    if (exception.error) {
      message = exception.error.message ?? message;
      status = exception.error.status ?? status;
      result = exception.error.result ?? result;
    }
    if (result?.message) {
      return [result.message].flat();
    } else {
      if (status) {
        return [`${message} (status: ${status})`];
      } else {
        return [message].flat();
      }
    }
  }

  parseErrorMessage(error: ApplicationError): string[] {
    if (Array.isArray(error)) {
      const errors = [];
      for (const item of error) {
        if (typeof item === 'string') {
          errors.push(item);
        } else {
          errors.push(this.swaggerExceptionToMessages(item));
        }
      }
      return errors.flat();
    }
    if (typeof error === 'string') {
      return [error];
    }
    if (typeof error === 'object') {
      return this.swaggerExceptionToMessages(error);
    }
  }
}
