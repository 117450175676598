<domat-tree-bar (globalView)="treeBarClicked($event)"></domat-tree-bar>

<div class="loading-icon-wrapper" *ngIf="loading">
  <img [src]="loadingIconSrc" class="loading-icon" />
</div>

<dx-tree-view
  *ngIf="navigationFinished"
  expandEvent="dblclick"
  width="100%"
  [dataSource]="dataSource"
  (onItemClick)="onItemClick($event)"
  (onItemExpanded)="onItemExpanded($event)"
  (onItemCollapsed)="onItemCollapsed($event)"
  (onContentReady)="onContentReady($event)"
  [selectByClick]="true"
  selectionMode="single"
  selectedExpr="isSelected"
  keyExpr="urowid"
  displayExpr="caption"
  dataStructure="plain"
  parentIdExpr="purowid"
  hasItemsExpr="hasItems"
  [virtualModeEnabled]="true"
  [focusStateEnabled]="false"
  scrollDirection="vertical"
  rootValue="---"
  itemTemplate="treeNodeTemplate"
>
  <ng-container *dxTemplate="let treeNode of 'treeNodeTemplate'">
    <a
      [routerLink]="routerLink(treeNode.urowid)"
      queryParamsHandling="merge"
      class=""
    >
      <div
        [id]="'navid-' + treeNode.urowid"
        class="tree-node"
        data-cy="tree-node"
      >
        <span class="caption o-link o-link--on-dark">
          <domat-icon class="caption-icon" [name]="treeNode.icon"></domat-icon>
          <span class="caption-text" [title]="treeNode.caption">
            {{ treeNode.caption }}
          </span>
        </span>
        <ng-container *ngIf="isServiceWorkflow">
          <span class="ticket-count">
            <span *ngIf="treeNode.totalTickets > 0" class="ticket-count-text">
              <domat-icon
                class="ticket-count-icon"
                name="settings"
                size="tiny"
              ></domat-icon>
              <ng-container *ngIf="treeNode.dueTickets > 0">
                <span class="due">{{ treeNode.dueTickets }}</span>
                &nbsp;/&nbsp;
              </ng-container>
              <span class="total">{{ treeNode.totalTickets }}</span>
            </span>
          </span>
        </ng-container>
      </div>
    </a>
  </ng-container>
</dx-tree-view>
