export function resolveIconFromNodeType(type: string): string {
  if (type === undefined || type === null || type === '') {
    return '';
  }
  const itemType = type.toLowerCase();
  switch (itemType) {
    case 'pro':
      return 'operator';
    case 'obj':
      return 'object';
    case 'zar':
      return 'facility';
    case 'dil':
      return 'part';
  }
}
