<dx-button
  [hint]="hint"
  [disabled]="disabled"
  (onClick)="onClick()"
  [class.loading]="loading"
>
  <div *dxTemplate="let data of 'content'">
    <dx-load-indicator
      [indicatorSrc]="indicatorSrc"
      class="button-indicator"
      [visible]="loading"
    >
    </dx-load-indicator>
    <span class="dx-button-text">{{ text }}</span>
  </div>
</dx-button>
