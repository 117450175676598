import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  simulateDownload(
    exportedData: any,
    fileName: string,
    fileMimeType: string,
    isUtf8: boolean = true
  ) {
    // oznaceni souboru ze je utf-8 kodovan, tak aby tomu rozumeli editory i Excel
    const blobContent = isUtf8 ? ['\ufeff', exportedData] : [exportedData];
    const blob = new Blob(blobContent, { type: fileMimeType });
    const url = URL.createObjectURL(blob);
    this.simulateDownloadFromUrl(url, fileName);
  }

  simulateDownloadFromUrl(url: string, fileName: string) {
    const link = document.createElement('a');
    if (link.download === undefined) {
      // Safari doesn't support this yet
      window.location.href = url;
    } else {
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
