import { ProductConfig } from '@domat/shared/data-access';

export const PRODUCT_CONFIG: ProductConfig = {
  companyLogoSrc: 'images/Logo_DCS_White.png',
  productLogoSrc: 'images/Logo_CP_Area_White.svg',
  footerImageSrc: 'images/Logo_DCS_Black.svg',
  footerImageAlt: 'Logo Domat',
  popupHeaderImageSrc: 'images/Logo_CP_Line_White.svg',
  popupContentImageSrc: 'images/Logo_DCS_Black.svg',
  companyName: 'Domat',
  productName: 'Merbon ContPort',
  companyFullName: 'Domat Control System s.r.o.',
  telephoneNumber: '+420 461 100 823',
  emailAddress: 'info@domat.cz',
  address: {
    postalCode: '530 06',
    city: 'Pardubice, Staré Čívice',
    street: 'U Panasonicu 376',
  },
  pagesWithFullWidthAndHeight: ['reports'],
  licenceAgreement: true,
  get copyright() {
    return `${new Date().getFullYear()} Domat Control System s.r.o. All Rights Reserved`;
  },
};
