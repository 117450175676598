import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FeatureTogglesService,
  InitialConfiguration,
  NavigationService,
  StaticConfigService,
} from '@domat/shared/data-access';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'domat-user-session',
  templateUrl: './user-session.component.html',
  styleUrls: ['./user-session.component.css'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'shell',
    },
  ],
})
export class UserSessionComponent implements OnInit {
  @Input() initialConfig: InitialConfiguration;

  showHelp: boolean = false;
  treeNodeId: string;
  popoverVisible: boolean = false;
  showAboutPopup: boolean = false;
  versionVisible: boolean = false;
  copyright: string = this.staticConfig.productConfig.copyright;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  Boolean = Boolean;

  constructor(
    private staticConfig: StaticConfigService,
    private featureToggles: FeatureTogglesService,
    private navigation: NavigationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showHelp = this.featureToggles.isEnabled('Help');
    this.navigation.currentTreeNodeId$.subscribe((id) => {
      this.treeNodeId = id;
    });
  }

  showPopover() {
    this.popoverVisible = !this.popoverVisible;
  }

  userSettings() {
    this.router.navigate(['/', 'user-settings', this.treeNodeId]);
  }

  toggleTooltip() {
    this.versionVisible = !this.versionVisible;
  }

  toggleAbout() {
    this.showAboutPopup = !this.showAboutPopup;
  }

  onContentReady(e) {
    const contentElement = e.component.content();
    contentElement.style.padding = '0';
  }

  logOff() {
    window.sessionStorage.clear();
  }
}
