import { InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '@domat/shared/utils';
import { FeatureTogglesService } from '../feature-toggles.service';
import { PermissionsService } from '../permissions.service';
import { StaticConfigService } from '../static-config.service';
import { DocumentTitleService } from './document-title.service';
import { NavigationService } from './navigation.service';
import { NavigationServiceMock } from './navigation.service.mock';
import { RouteChangesListenerService } from './route-changes-listener.service';

export const NAVIGATION_SERVICE = new InjectionToken<string>(
  'NavigationService'
);

export function navigationServiceFactory(isMock: boolean) {
  return function (
    router: Router,
    routeChanges: RouteChangesListenerService,
    appTitleService: DocumentTitleService,
    staticConfig: StaticConfigService,
    featureToggles: FeatureTogglesService,
    permissions: PermissionsService,
    log: LoggingService
  ) {
    if (isMock) {
      return new NavigationServiceMock();
    } else {
      return new NavigationService(
        router,
        routeChanges,
        appTitleService,
        staticConfig,
        featureToggles,
        permissions,
        log
      );
    }
  };
}
