<header class="wrapper">
  <domat-company-logo
    class="company-logo"
    [companyName]="companyName"
    [src]="companyLogoSrc"
  ></domat-company-logo>
  <domat-main-navigation class="main-navigation"></domat-main-navigation>
  <domat-user-session
    class="user-session"
    [initialConfig]="initialConfig"
  ></domat-user-session>
  <domat-product-logo
    class="product-logo"
    [src]="productLogoSrc"
  ></domat-product-logo>
</header>
