<div
  [attr.data-cy]="'alert-component-' + type"
  class="alert"
  id="alert"
  [ngClass]="type"
  *ngIf="message"
  @reveal
>
  <ng-container *ngIf="messageIsArray(); else messageIsNotArray">
    <ng-container *ngFor="let m of firstMessages()">
      <div class="row" *ngIf="m">{{ m }}<br /></div>
    </ng-container>
    <domat-action-link
      *ngIf="moreThen5Messages() && !showAll"
      class="show-hide"
      (clicked)="show()"
    >
      Celkem {{ message.length }} - zobrazit všechny
    </domat-action-link>
    <domat-action-link
      *ngIf="moreThen5Messages() && showAll"
      class="show-hide"
      (clicked)="hide()"
    >
      Celkem {{ message.length }} - skrýt
    </domat-action-link>
  </ng-container>
  <ng-template #messageIsNotArray>
    {{ message }}
  </ng-template>
  <domat-action-link
    (clicked)="close()"
    title="Zavřít"
    class="close"
    data-cy="alert-component-close"
  >
    <domat-icon name="close" size="large"></domat-icon>
  </domat-action-link>
</div>
