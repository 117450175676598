import { Component, Input, OnInit } from '@angular/core';
import {
  NavigationService,
  StaticConfigService,
} from '@domat/shared/data-access';
import { GlobalViewService } from '@domat/shared/utils';
import { Router } from '@angular/router';

@Component({
  selector: 'domat-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() breadcrumbs: Array<{ id: string; caption: string }> = [];

  currentPrimaryPageId: string;

  globalViewCaption: string;
  globalView: boolean = false;

  pageRequiresFullWidthAndHeight: boolean;

  constructor(
    private navigation: NavigationService,
    private staticConfig: StaticConfigService,
    private globalViewService: GlobalViewService,
    private router: Router
  ) {
    this.globalViewService.globalViewCaption.subscribe((value) => {
      this.globalViewCaption = value;
    });
  }

  ngOnInit() {
    this.navigation.currentPrimaryPageId$.subscribe((id) => {
      this.currentPrimaryPageId = id || '';
      const fullPages = this.staticConfig.productConfig
        .pagesWithFullWidthAndHeight;
      this.pageRequiresFullWidthAndHeight = fullPages && fullPages.includes(id);
    });
    this.navigation.currentTreeNodeId$.subscribe((id) => {
      this.globalView = id === this.globalViewService.globalViewMarker;
    });
  }

  onBreadcrumbClick(currentPrimaryPageId, currentTreeNodeId) {
    this.router.navigate(['/', currentPrimaryPageId, currentTreeNodeId]);
  }
}
