import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FileSizePipe } from './pipes/file-size.pipe';
import { ConsolePipe } from './pipes/console.pipe';
import { FileKindPipe } from './pipes/file-kind.pipe';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { TimeZonePipe } from './pipes/time-zone.pipe';
import { TreeNodePathPipe } from './pipes/tree-node-path.pipe';

@NgModule({
  imports: [
    CommonModule,
    LoggerModule.forRoot({
      serverLoggingUrl: '',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.WARN,
    }),
  ],
  providers: [],
  declarations: [
    FileSizePipe,
    ConsolePipe,
    FileKindPipe,
    TimeZonePipe,
    TreeNodePathPipe,
  ],
  exports: [
    FileSizePipe,
    ConsolePipe,
    FileKindPipe,
    TimeZonePipe,
    TreeNodePathPipe,
  ],
})
export class SharedUtilsModule {}
