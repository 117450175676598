import { Location } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { BASE_API_URL } from '../api-client.generated';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(@Inject(BASE_API_URL) private apiUrl: string) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const apiRequest = request.clone({
      url: this.prepareUrl(request.url),
      withCredentials: true,
    });

    return next.handle(apiRequest).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            window.location.replace(
              `/Account/Login?ReturnUrl=${encodeURIComponent(
                window.location.pathname
              )}`
            );
          }
        }
        return throwError(err);
      })
    );
  }

  private prepareUrl(url: string): string {
    url = this.isAbsoluteUrl(url)
      ? url
      : Location.joinWithSlash(this.apiUrl, url);
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

  private isAbsoluteUrl(url: string): boolean {
    const absolutePattern = /^https?:\/\//i;
    return absolutePattern.test(url);
  }
}
