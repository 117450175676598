<div *ngIf="!globalView; else globalViewTemplate" class="wrapper">
  <div
    *ngFor="let item of breadcrumbs; first as isFirst; last as isLast"
    class="items"
    [ngClass]="{
      strong: pageRequiresFullWidthAndHeight
    }"
  >
    <a
      (click)="onBreadcrumbClick(currentPrimaryPageId, item.id)"
      class="item o-link o-link--on-light"
      >{{ item.caption }}</a
    >
    <div class="item">
      <domat-icon
        *ngIf="!isLast"
        name="showRightOrHideLeft"
        size="small"
      ></domat-icon>
    </div>
  </div>
</div>
<ng-template #globalViewTemplate>
  <div
    class="items"
    [ngClass]="{
      strong: pageRequiresFullWidthAndHeight
    }"
  >
    {{ globalViewCaption }}
  </div>
</ng-template>
