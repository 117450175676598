import { Component, OnInit, Input } from '@angular/core';
import { ProductConfig, StaticConfigService } from '@domat/shared/data-access';

@Component({
  selector: 'domat-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css'],
})
export class GeneralInfoComponent implements OnInit {
  @Input() popupContentImageSrc: string;
  productConfig: ProductConfig = this.staticConfig.productConfig;

  constructor(private staticConfig: StaticConfigService) {}
  ngOnInit() {}
}
