import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class GlobalViewService {
  readonly globalViewMarker = '_';
  readonly globalViewCaption = this.translocoService.selectTranslate<string>(
    'globalMode'
  );

  constructor(private translocoService: TranslocoService) {}
}
