import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class DocumentTitleService {
  constructor(private browserTitleService: Title) {}

  public setTitle(
    productName: string,
    pageName?: string,
    treeNodeName?: string
  ) {
    const separator1 = '-';
    const separator2 = '::';
    let title = '';
    if (pageName && treeNodeName) {
      title = `${pageName} ${separator1} ${productName} ${separator2} ${treeNodeName}`;
    } else if (pageName) {
      title = `${pageName} ${separator1} ${productName}`;
    } else {
      title = `${productName}`;
    }
    this.browserTitleService.setTitle(title);
  }
}
