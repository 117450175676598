import { Injectable } from '@angular/core';

type Guid = string;

@Injectable({
  providedIn: 'root',
})
export class GuidService {
  constructor() {}

  /**
   * Strips a descriptive part of an id.
   *
   * Example:
   * 00-0DF3AF59-2040-E911-8106-0050560110B6
   * =>
   * 0DF3AF59-2040-E911-8106-0050560110B6
   */
  convertTreeNodeIdToGuid(treeNodeId: string): Guid {
    return treeNodeId.slice(3);
  }

  convertOperatorGuidToTreeNodeId(guid: Guid): string {
    return `00-${guid.toUpperCase()}`;
  }
}
