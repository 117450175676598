import { Component, OnInit } from '@angular/core';
import { LicencesDataService } from '../services/licences-data.service';
import { Licence } from './licence';
@Component({
  selector: 'domat-third-party-licences',
  templateUrl: './third-party-licences.component.html',
  styleUrls: ['./third-party-licences.component.css'],
})
export class ThirdPartyLicencesComponent implements OnInit {
  licences: Licence[];
  constructor(private data: LicencesDataService) {}
  ngOnInit() {
    this.licences = this.data.getLicences();
  }
}
