import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'domat-shell',
  template: `
    <domat-main-layout>
      <div class="page-wrapper">
        <div class="page-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </domat-main-layout>
  `,
  styleUrls: ['./shell.component.css'],
})
export class ShellComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
