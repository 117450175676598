import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'domat-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.css'],
})
export class InfoBlockComponent implements OnInit {
  @Input() width: number;
  @Input() height: number;
  @Input() caption: string;
  @Input() type = 'string';
  @Input() content: string;

  @HostBinding('class') classList = 'info-block';

  constructor() {}

  ngOnInit() {
    this.setUpClassesForWidthAndHeight();
  }

  protected setUpClassesForWidthAndHeight() {
    if (this.width) {
      this.classList = `${this.classList} ${this.width}`;
    }
    if (this.height) {
      this.classList = `${this.classList} ${this.height}`;
    }
  }
}
