import { Injectable } from '@angular/core';

import { AppRoles } from '../model/AppRoles';
import { DynamicConfigService } from './dynamic-config.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private config: DynamicConfigService) {}

  checkRole(appRole: AppRoles) {
    if (appRole) {
      if (this.config.appConfig.user.roles.includes(appRole)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
