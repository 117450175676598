import { Injectable } from '@angular/core';
import ODataContext from 'devextreme/data/odata/context';

import { TreeNode } from '../../model/TreeNode';
import { EndpointsService } from '../endpoints.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationTreePathService {
  constructor(private endpoints: EndpointsService) {}

  getPathToTarget(treeNodeId: string): Promise<TreeNode[]> {
    const context = new ODataContext({
      url: `${this.endpoints.navigationTreeUrl()}('${treeNodeId}')`,
      entities: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        NavigationTree: {
          key: 'urowid',
          keyType: 'string',
        },
      },
      version: 4,
      beforeSend: (request) => {
        // Url konci /(), nahradime custom dotazem
        request.url = request.url.replace('()', 'Default.GetPathToTarget()');
      },
    });
    return context.get('', null);
  }
}
