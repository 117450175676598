import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BASE_API_URL } from '../api-client.generated';

@Injectable({
  providedIn: 'root',
})
export class EndpointsService {
  constructor(@Inject(BASE_API_URL) private apiUrl: string) {}

  attachmentDownloadUrl(
    currentTreeNodeId: string,
    ticketId: number,
    attachmentId: number
  ): string {
    return this.apiEndpoint(
      `/api/TreeNodes/${currentTreeNodeId}/Tickets/${ticketId}/Attachments/${attachmentId}`
    );
  }

  attachmentUploadUrl(currentTreeNodeId: string, id: number): string {
    return this.apiEndpoint(
      `/api/TreeNodes/${currentTreeNodeId}/Tickets/${id}/Attachments`
    );
  }

  reportsGenerationUrl(reportKey: string) {
    return this.apiEndpoint(`/api/Reports/${reportKey}`);
  }

  reportTemplatesUpload(treeNodeId: string): string {
    return this.apiEndpoint(`/api/TreeNodes/${treeNodeId}/ReportTemplates`);
  }

  navigationTreeUrl(): string {
    return this.apiEndpoint('/odata/NavigationTree');
  }

  informationFilesUploadUrl(treeNodeId: string) {
    return this.apiEndpoint(`/api/TreeNodes/${treeNodeId}/Files`);
  }

  informationFileDownload(treeNodeId: string, fileId: number) {
    return this.apiEndpoint(
      `/api/TreeNodes/${treeNodeId}/Files/${fileId}/Download`
    );
  }

  importProfileValues(
    operatorId: string,
    reportKind: string,
    reportId: string,
    year: number,
    month: number
  ) {
    return this.apiEndpoint(
      `/api/Operators/${operatorId}/Reports${reportKind}/${reportId}/ProfileValues/Import?year=${year}&month=${month}`
    );
  }

  importPriceListUrl(
    validateFrom: string,
    targetListName: string,
    reportKind: string
  ) {
    return `/api/Reports${reportKind}/PriceLists/Import?validFrom=${validateFrom}&targetListName=${targetListName}`;
  }

  /**
   * Vytvori plnou url k api endpointu
   * @param path absolutni cesta k endpointu (napr. /api/Data)
   */
  private apiEndpoint(path: string): string {
    return Location.joinWithSlash(this.apiUrl, path);
  }
}
