import { Component, Inject, OnInit } from '@angular/core';
import {
  NAVIGATION_SERVICE,
  NavigationServiceInterface,
  StaticConfigService,
} from '@domat/shared/data-access';
import ruMessages from 'devextreme/localization/messages/ru.json';
import { locale, loadMessages } from 'devextreme/localization';
import { PRODUCT_CONFIG } from './product.config';

@Component({
  selector: 'domat-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  pageRequiresFullWidthAndHeight: boolean;

  constructor(
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface,
    private staticConfig: StaticConfigService
  ) {
    staticConfig.productConfig = PRODUCT_CONFIG;
    loadMessages(ruMessages);
    locale(navigator.language);
  }

  ngOnInit() {
    this.navigation.currentPrimaryPageId$.subscribe((pageId) => {
      const fullPages = this.staticConfig.productConfig
        .pagesWithFullWidthAndHeight;
      this.pageRequiresFullWidthAndHeight =
        fullPages && fullPages.includes(pageId);
    });
  }
}
