import { Component, OnInit } from '@angular/core';

import { IconsService } from '../../services/icons.service';

@Component({
  selector: 'domat-spinner-icon',
  template: `
    <dx-load-indicator [indicatorSrc]="indicatorImage"></dx-load-indicator>
  `,
})
export class SpinnerIconComponent implements OnInit {
  indicatorImage = this.icons.spinnerIconUrl;

  constructor(private icons: IconsService) {}

  ngOnInit() {}
}
