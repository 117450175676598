<domat-main-header
  class="layout-header"
  id="layout-header"
  [productConfig]="productConfig"
  [initialConfig]="initialConfig"
></domat-main-header>

<dx-drawer
  class="layout-body"
  position="before"
  template="sidePanel"
  [animationDuration]="250"
  [maxSize]="maxDrawerWidth"
  [opened]="treeNavService.treeNavOpened$ | async"
  (onOptionChanged)="drawerOptionChanged($event)"
  [closeOnOutsideClick]="shaderEnabled"
  [minSize]="minMenuSize"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  [shading]="shaderEnabled"
>
  <domat-tree-navigation
    *dxTemplate="let treeNavigation of 'sidePanel'"
    class="tree-navigation"
    (treeNodeClicked)="treeNodeClicked($event)"
    (pathToNode)="updateBreadcrumbs($event)"
  ></domat-tree-navigation>

  <div class="layout-body-content">
    <domat-global-alert></domat-global-alert>
    <domat-navigation-bar
      class="navigation-bar"
      [breadcrumbs]="breadcrumbs"
    ></domat-navigation-bar>
    <dx-scroll-view [disabled]="pageRequiresFullWidthAndHeight">
      <div class="scroll-view-content">
        <ng-content></ng-content>
      </div>
    </dx-scroll-view>
  </div>
</dx-drawer>
