import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { BaseUrlInterceptor } from './interceptors/http-client.interceptor';
import { BASE_API_URL } from './api-client.generated';

@NgModule({
  imports: [CommonModule, HttpClientModule],
})
export class SharedDataAccessModule {
  public static forRoot(
    environment: any
  ): ModuleWithProviders<SharedDataAccessModule> {
    return {
      ngModule: SharedDataAccessModule,
      providers: [
        {
          provide: BASE_API_URL,
          useValue: environment.apiUrl,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BaseUrlInterceptor,
          multi: true,
          deps: [BASE_API_URL],
        },
      ],
    };
  }
}
