import { Injectable } from '@angular/core';
import {
  LocalizationConfigService,
  TimeZoneService,
} from '@domat/shared/utils';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';

import {
  InitialConfiguration,
  InitialConfigurationService,
} from '../api-client.generated';
import { Router } from '@angular/router';

export function loadConfig(
  configService: DynamicConfigService,
  configurationService: InitialConfigurationService,
  translateService: TranslocoService,
  localizationService: LocalizationConfigService,
  timezoneService: TimeZoneService,
  router: Router
): () => Promise<boolean> {
  return function () {
    return configurationService
      .getInitialConfiguration()
      .pipe(
        switchMap((response: InitialConfiguration) => {
          configService.appConfig = response;
          translateService.setActiveLang(response.user.language);
          localizationService.configureDevextreme(response.user.language);
          timezoneService.setCurrentTimeZone(response.user.timeZone);
          return of(true);
        }),
        catchError((e) => {
          if (e.status !== 401) {
            setTimeout(() => {
              router.navigate(['/error'], { state: { error: e } });
            });
          }
          return of(false);
        })
      )
      .toPromise();
  };
}

@Injectable({
  providedIn: 'root',
})
export class DynamicConfigService {
  appConfig: InitialConfiguration;

  constructor() {}
}
