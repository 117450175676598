import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LoggingConfigService {
  constructor(private logger: NGXLogger) {}

  public configure(isProduction: boolean, isDebug: boolean) {
    if (isProduction) {
      this.logger.updateConfig({
        serverLoggingUrl: '/Logs',
        level: NgxLoggerLevel.WARN,
        serverLogLevel: NgxLoggerLevel.WARN,
      });
      if (isDebug) {
        this.logger.updateConfig({
          serverLoggingUrl: '',
          level: NgxLoggerLevel.TRACE,
          serverLogLevel: NgxLoggerLevel.OFF,
        });
      }
    } else {
      this.logger.updateConfig({
        serverLoggingUrl: '',
        level: NgxLoggerLevel.TRACE,
        serverLogLevel: NgxLoggerLevel.OFF,
        colorScheme: ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
      });
    }
  }
}
