<ng-container *transloco="let t">
  <div class="tree-bar">
    <domat-shell-button
      class="bar-button"
      iconName="menu"
      [tooltip]="t('shell.treeBar.showHide')"
      (clicked)="treeNavService.toggleTreeNav()"
    ></domat-shell-button>

    <domat-shell-button
      *ngIf="
        currentPrimaryPageId === 'service-workflow' ||
        currentPrimaryPageId === 'communication' ||
        currentPrimaryPageId === 'online-data' ||
        currentPrimaryPageId === 'reports'
      "
      [active]="globeActive"
      [tooltip]="t('shell.treeBar.globalMode')"
      class="bar-button"
      iconName="globe"
      (clicked)="navigateToRoot($event)"
    ></domat-shell-button>

    <a
      *ngIf="!isSearchOpened"
      [@showSearchTree]
      [title]="t('shell.treeBar.search')"
      class="bar-button o-link o-link--on-dark"
      (click)="openSearch()"
    >
      <domat-icon name="zoom"></domat-icon>
    </a>

    <dx-autocomplete
      [@showSearchTree]
      *ngIf="isSearchOpened"
      [dataSource]="treeNavSearchDataSource"
      name="searchTreeTB"
      [maxItemCount]="30"
      [minSearchLength]="3"
      searchExpr="caption"
      searchMode="contains"
      [placeholder]="t('shell.treeBar.search')"
      valueExpr="caption"
      (onOpened)="onAutocompleteOpened($event)"
      (onInitialized)="onInitialized($event)"
      (onItemClick)="onItemClicked($event)"
    >
      <div *dxTemplate="let item of 'item'">
        <div class="searched-item" data-cy="tree-nav-searched-item">
          {{ item.treecaption }}
        </div>
      </div>
    </dx-autocomplete>

    <domat-shell-button
      *ngIf="isSearchOpened"
      iconName="close"
      [title]="t('shell.treeBar.closeSearch')"
      (clicked)="closeSearch()"
    ></domat-shell-button>
  </div>
</ng-container>
