import { InjectionToken } from '@angular/core';

export const ENV = new InjectionToken('env');

export interface Environment {
  production: boolean;
  hmr: boolean;
  apiUrl: string;
  deployUrl: string;
  palette: string;
}
