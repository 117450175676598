import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'domat-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
  animations: [
    trigger('reveal', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('150ms', style({ height: '*', opacity: 1 })),
      ]),
    ]),
  ],
})
export class AlertComponent implements OnInit {
  @Input() type: string;
  @Input() message: string | string[];

  @Output() closeAlert = new EventEmitter();

  showAll: boolean = false;

  constructor() {}

  ngOnInit() {}

  show() {
    this.showAll = true;
    document.getElementById('alert').style.height = '52vh';
  }

  hide() {
    this.showAll = false;
    document.getElementById('alert').style.height = 'initial';
  }

  close() {
    this.closeAlert.emit();
  }

  firstMessages(): string[] {
    return this.messageIsArray()
      ? this.showAll
        ? (this.message as string[])
        : (this.message as string[]).slice(0, 5)
      : [];
  }

  moreThen5Messages(): boolean {
    return this.messageIsArray() && this.message.length > 5;
  }

  messageIsArray() {
    return Array.isArray(this.message);
  }
}
