import { Injectable } from '@angular/core';
import { LICENCES } from '../third-party-licences/third-party-licences-data';
import { Licence } from '../third-party-licences/licence';
@Injectable({
  providedIn: 'root',
})
export class LicencesDataService {
  licences: Licence[] = LICENCES;
  constructor() {}
  getLicences() {
    this.licences.sort((a, b) => a.name.localeCompare(b.name));
    return this.licences;
  }
}
