export enum AppRoles {
  superAdmin = 'Administrator',
  ticketViewer = 'Ticket Viewer',
  ticketCreator = 'Ticket Creator',
  attachmentViewer = 'Attachment Viewer',
  attachmentEditor = 'Attachment Editor',
  onlineDataViewer = 'Online Data Viewer',
  onlineDataEditor = 'Online Data Editor',
  templateViewerName = 'Template Viewer',
  templateEditorName = 'Template Editor',
  reportViewerName = 'Report Viewer',
  reportEditorName = 'Report Editor',
}
