import { Component, OnInit } from '@angular/core';
import { StaticConfigService } from '@domat/shared/data-access';
import { UrlUtilsService } from '@domat/shared/utils';

@Component({
  selector: 'domat-application-info',
  templateUrl: './application-info.component.html',
  styleUrls: ['./application-info.component.css'],
})
export class ApplicationInfoComponent implements OnInit {
  popupHeaderImageSrc = this.urlUtils.asset(
    this.staticConfig.productConfig.popupHeaderImageSrc
  );
  popupContentImageSrc = this.urlUtils.asset(
    this.staticConfig.productConfig.popupContentImageSrc
  );
  content: string = 'general';
  constructor(
    public staticConfig: StaticConfigService,
    private urlUtils: UrlUtilsService
  ) {}

  ngOnInit() {}
  general() {
    this.content = 'general';
  }
  thirdParty() {
    this.content = 'thirdparty';
  }
  licenceAgreement() {
    this.content = 'licenceagreement';
  }
}
