import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'domat-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.css'],
})
export class IconComponent implements OnInit {
  @Input() name: string;
  @Input() size = 'big';

  currentUrl: string;

  constructor() {}

  ngOnInit() {}
}
