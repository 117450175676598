import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NAVIGATION_SERVICE,
  NavigationServiceInterface,
  TreeNode,
} from '@domat/shared/data-access';
import {
  GlobalConstants,
  TreeNavigationUiService,
  GlobalViewService,
} from '@domat/shared/utils';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import DataSource from 'devextreme/data/data_source';
import { SubSink } from 'subsink';

import { TreeNavigationDataSourceService } from './tree-navigation-data-source.service';

@Component({
  selector: 'domat-tree-bar',
  templateUrl: './tree-bar.component.html',
  styleUrls: ['./tree-bar.component.css'],
  animations: [
    trigger('showSearchTree', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('150ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'shell',
    },
  ],
})
export class TreeBarComponent implements OnInit, OnDestroy {
  @Output() globalView = new EventEmitter<any>();

  @Input() isSearchOpened: boolean;

  currentPrimaryPageId: string;
  currentSecondaryPageId: string;
  globeActive: boolean;

  treeNavSearchDataSource: DataSource;

  private subs = new SubSink();

  constructor(
    public treeNavService: TreeNavigationUiService,
    private router: Router,
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface,
    private globalViewService: GlobalViewService,
    private treeNavigationData: TreeNavigationDataSourceService
  ) {}

  ngOnInit() {
    this.subs.sink = this.navigation.currentPrimaryPageId$.subscribe((id) => {
      if (id) {
        this.currentPrimaryPageId = id;
      }
    });
    this.subs.sink = this.navigation.currentSecondaryPageId$.subscribe((id) => {
      if (id && this.currentPrimaryPageId !== id) {
        this.currentSecondaryPageId = id;
      }
    });
    this.subs.sink = this.navigation.currentTreeNodeId$.subscribe((id) => {
      if (id === GlobalConstants.globalViewMark) {
        this.globeActive = true;
      } else {
        this.globeActive = false;
      }
    });

    this.treeNavSearchDataSource = this.treeNavigationData.getDataSource();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  openSearch() {
    this.isSearchOpened = true;
  }

  closeSearch() {
    this.isSearchOpened = false;
  }

  navigateToRoot(event) {
    const navigation = [
      '/',
      this.currentPrimaryPageId,
      this.globalViewService.globalViewMarker,
    ];
    if (
      this.currentSecondaryPageId &&
      this.currentPrimaryPageId !== 'service-workflow' &&
      this.currentPrimaryPageId !== 'online-data'
    ) {
      navigation.push(this.currentSecondaryPageId);
    }
    this.router.navigate(navigation);
    this.globalView.emit(event);
    this.navigation.setNextTreeNodeData({
      id: this.globalViewService.globalViewMarker,
      caption: this.globalViewService.globalViewMarker,
    });
  }

  onInitialized(e) {
    setTimeout(() => e.component.focus(), 200);
  }

  onAutocompleteOpened(e) {
    e.component.content().parentElement.classList.add('dx-autocomplete-width');
  }

  onItemClicked(e) {
    this.onItemSelected(e.itemData);
  }

  onItemSelected(item) {
    if (typeof item === 'object') {
      const urowid = (item as TreeNode).urowid;
      if (
        !this.currentSecondaryPageId ||
        this.currentPrimaryPageId === 'information'
      ) {
        this.router.navigate(['/', this.currentPrimaryPageId, urowid]);
      } else {
        this.router.navigate([
          '/',
          this.currentPrimaryPageId,
          urowid,
          this.currentSecondaryPageId,
        ]);
      }
    }
  }
}
