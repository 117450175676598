<ng-container *ngIf="alerts">
  <ng-container *ngFor="let alert of alerts">
    <domat-alert
      class="alert"
      [type]="alert.type"
      [message]="alert.message"
      (closeAlert)="close(alert)"
      @reveal
    ></domat-alert>
  </ng-container>
</ng-container>
