<nav class="menu u-no-select" *transloco="let t">
  <ul class="menu-list">
    <li class="menu-item" *ngFor="let page of pages">
      <a
        [routerLink]="
          page.ignorePathSegment
            ? ['/', currentPrimaryPageId, currentTreeNodeId]
            : ['/', currentPrimaryPageId, currentTreeNodeId, page.id]
        "
        [queryParams]="page.queryParams"
        [queryParamsHandling]="'merge'"
        [ngClass]="{
          'o-link--on-dark--active':
            page.id === currentSecondaryPageId ||
            currentQueryParams?.tab === page.queryParams?.tab
        }"
        class="menu-link o-link o-link--on-dark"
        [attr.data-cy]="'secondary-nav-' + page.id"
      >
        <domat-icon
          *ngIf="page.icon"
          [name]="page.icon"
          size="default"
        ></domat-icon>
        <!--
          t(
          shell.secondaryNavigation.combined,
          shell.secondaryNavigation.variables,
          shell.secondaryNavigation.graph,
          shell.secondaryNavigation.importOnlineData,
          shell.secondaryNavigation.exportOnlineData,
          shell.secondaryNavigation.reportList,
          shell.secondaryNavigation.ticketsList,
          shell.secondaryNavigation.newTicket,
          shell.secondaryNavigation.templates,
          )
        -->
        <span class="menu-label">{{ t(page.translocoId) }}</span>
      </a>
    </li>
  </ul>
</nav>
