import { Injectable } from '@angular/core';
import { GuidService } from '@domat/shared/utils';

import { TreeNode } from '../../model';
import { DynamicConfigService } from '../dynamic-config.service';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService {
  constructor(
    private guidService: GuidService,
    private dynamicConfig: DynamicConfigService
  ) {}

  getOperatorNameFromTreeNodeId(operatorId) {
    return this.getOperators().find(
      (operator) =>
        this.guidService.convertTreeNodeIdToGuid(operator.id).toLowerCase() ===
        operatorId.toString().toLowerCase()
    )?.name;
  }

  getOperatorNameFromGuid(operatorId) {
    return this.getOperators().find(
      (operator) =>
        operator.id.toLowerCase() === operatorId.toString().toLowerCase()
    )?.name;
  }

  getDefaultOperatorId(): string {
    let defaultOperator = this.dynamicConfig.appConfig.user.defaultOperator;
    if (!defaultOperator) {
      defaultOperator = this.dynamicConfig.appConfig.operators[0].id;
    }
    return defaultOperator;
  }

  getOperators(): TreeNode[] {
    return this.dynamicConfig.appConfig.operators.map((operator) => ({
      ...operator,
      caption: operator.name,
    }));
  }
}
