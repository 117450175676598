import { Injectable } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RouteLoadingService {
  routeIsLoading$: Observable<boolean>;
  loadingText$: Observable<string>;

  private loading = new BehaviorSubject<{
    state: boolean;
    text: string;
  }>({ state: false, text: '' });

  constructor(private router: Router) {
    const loading$ = this.loading.asObservable().pipe(
      filter((isLoading) => !(typeof isLoading === 'undefined')),
      share()
    );
    this.routeIsLoading$ = loading$.pipe(
      map((obj) => obj.state),
      distinctUntilChanged()
    );
    this.loadingText$ = loading$.pipe(
      map((obj) => obj.text || ''),
      distinctUntilChanged()
    );

    this.router.events
      .pipe(
        map((routerEvent: Event) => {
          if (routerEvent instanceof NavigationStart) {
            this.loading.next({ state: true, text: '' });
          }
          if (
            routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationError ||
            routerEvent instanceof NavigationCancel
          ) {
            this.loading.next({ state: false, text: '' });
          }
        })
      )
      .subscribe();
  }

  changeRouteLoadingState(state: boolean, text: string = '') {
    this.loading.next({ state, text });
  }
}
