import { Component, Input, OnInit } from '@angular/core';
import {
  AppPage,
  DynamicConfigService,
  PermissionsService,
  AppRoles,
} from '@domat/shared/data-access';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'domat-primary-navigation',
  templateUrl: './primary-navigation.component.html',
  styleUrls: ['./primary-navigation.component.css'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'shell',
    },
  ],
})
export class PrimaryNavigationComponent implements OnInit {
  @Input() currentPrimaryPageId: string;
  @Input() currentTreeNodeId: string;
  @Input() pages: AppPage[];

  grafanaUrl: string;
  canViewOnlineData: boolean;

  informationMenuItem: AppPage;

  constructor(
    public dynamicConfig: DynamicConfigService,
    private permissions: PermissionsService
  ) {
    this.grafanaUrl = dynamicConfig.appConfig.settings.grafanaUrl;
  }

  ngOnInit() {
    this.canViewOnlineData = this.permissions.checkRole(
      AppRoles.onlineDataViewer
    );

    this.informationMenuItem = this.pages.find((x) => x.id === 'information');
    this.pages = this.pages.filter(
      (x) => x.id !== 'information' && x.id !== 'user-settings'
    );
    if (!this.canViewOnlineData) {
      this.pages = this.pages.filter((x) => x.id !== 'online-data');
    }
  }

  routerLinkTreeNode(page: AppPage) {
    if (this.currentTreeNodeId) {
      return ['/', page.id, this.currentTreeNodeId];
    } else {
      return ['/', page.id];
    }
  }

  hrefTreeNode(primaryPageName: string) {
    if (this.currentTreeNodeId) {
      return `/${primaryPageName}/${this.currentTreeNodeId}`;
    } else {
      return `/${primaryPageName}`;
    }
  }
}
