<dx-button
  [hint]="tooltip ? tooltip : ''"
  template="button"
  (onClick)="clicked.emit($event)"
  [ngClass]="{ active: active }"
>
  <domat-icon
    *dxTemplate="let button of 'button'"
    [name]="iconName"
  ></domat-icon>
</dx-button>
