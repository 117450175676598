<nav class="menu u-no-select" *transloco="let t">
  <ul class="menu-list">
    <li class="menu-item">
      <a
        [routerLink]="routerLinkTreeNode(informationMenuItem)"
        [ngClass]="{
          'o-link--on-dark--active':
            informationMenuItem.id === currentPrimaryPageId
        }"
        class="menu-link o-link o-link--on-dark"
        [title]="t(informationMenuItem.translocoId)"
        [attr.data-cy]="'primary-nav-' + informationMenuItem.id"
      >
        <domat-icon [name]="informationMenuItem.icon"></domat-icon>
        <!--
          t(
          shell.primaryNavigation.information,
          shell.primaryNavigation.onlineData,
          shell.primaryNavigation.serviceWorkflow,
          shell.primaryNavigation.reports,
          )
        -->
        <span class="menu-label">{{ t(informationMenuItem.translocoId) }}</span>
      </a>
    </li>
    <!-- <li class="menu-item">
      <a
        [href]="grafanaUrl"
        target="_blank"
        class="menu-link o-link o-link--on-dark"
        [attr.data-cy]="'primary-nav-dashboard'"
      >
        <domat-icon name="dashboard"></domat-icon>
        <span class="menu-label"
          >{{ t('shell.primaryNavigation.dashboard') }}
        </span>
        <domat-icon
          class="dashboard-link-icon"
          name="popUp"
          size="tiny"
        ></domat-icon>
      </a>
    </li> -->
    <li class="menu-item" *ngFor="let page of pages">
      <a
        [routerLink]="routerLinkTreeNode(page)"
        [ngClass]="{
          'o-link--on-dark--active': page.id === currentPrimaryPageId
        }"
        class="menu-link o-link o-link--on-dark"
        [title]="t(page.translocoId)"
        [attr.data-cy]="'primary-nav-' + page.id"
      >
        <domat-icon [name]="page.icon"></domat-icon>
        <span class="menu-label">{{ t(page.translocoId) }}</span>
      </a>
    </li>
  </ul>
</nav>
