import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { IconsService } from '../../services/icons.service';

const BACKGROUND_COLOR = 'rgba(255, 255, 255, 0.8)';

@Component({
  selector: 'domat-spinner-panel',
  template: `
    <div class="wrapper" #wrapper>
      <dx-load-panel
        [visible]="spinning"
        [delay]="delay"
        [message]="message"
        [showPane]="showPane"
        [indicatorSrc]="indicatorImage"
        [closeOnOutsideClick]="false"
        [shading]="true"
        [shadingColor]="shadingColor"
      >
        <dxo-position
          [at]="at"
          [my]="my"
          [offset]="offset"
          collision="fit"
          [of]="contentElement"
        ></dxo-position>
      </dx-load-panel>
      <ng-content></ng-content>
    </div>
  `,
})
export class SpinnerPanelComponent implements AfterViewInit, OnInit {
  @Input() message = '';
  @Input() spinning: boolean;
  @Input() global: boolean = false;
  @Input() element: string = null;
  @Input() center: boolean = false;

  at = 'center top';
  my = 'center top';
  offset = '0 30';

  contentElement: HTMLElement;
  delay = 0;
  indicatorImage = this.icons.spinnerIconUrl;
  shadingColor = BACKGROUND_COLOR;
  showPane = false;

  constructor(private icons: IconsService) {}

  ngOnInit(): void {
    this.showPane = this.message ? this.message.length > 0 : false;
    if (this.center) {
      this.at = 'center center';
      this.my = 'center center';
      this.offset = '0 0';
    }
  }

  ngAfterViewInit() {
    // HACK manualni nalezeni spravneho elementu ve strance,
    // pomoci ng-content a ViewChild se to nedarilo
    setTimeout(
      () =>
        (this.contentElement = document.querySelector(
          this.element || '.layout-body-content'
        )),
      0
    );
  }
}
