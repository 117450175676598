import { Injectable } from '@angular/core';
import { ProductConfig } from '../model';

@Injectable({
  providedIn: 'root',
})
export class StaticConfigService {
  productConfig: ProductConfig;

  constructor() {}
}
