import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  Router,
} from '@angular/router';
import {
  NAVIGATION_SERVICE,
  NavigationServiceInterface,
  NavigationTreePathService,
  NavigationTreeStoreService,
  TreeNode,
  StaticConfigService,
} from '@domat/shared/data-access';
import { IconsService } from '@domat/shared/ui';
import {
  GlobalConstants,
  LoggingService,
  TreeNavigationUiService,
} from '@domat/shared/utils';
import DataSource from 'devextreme/data/data_source';
import DxTreeView from 'devextreme/ui/tree_view';
import { interval, race } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'domat-tree-navigation',
  templateUrl: './tree-navigation.component.html',
  styleUrls: ['./tree-navigation.component.css'],
})
export class TreeNavigationComponent implements OnInit, OnDestroy {
  @Output() treeNodeClicked = new EventEmitter<any>();
  @Output() pathToNode = new EventEmitter<TreeNode[]>();

  loading: boolean;
  loadingIconSrc: string;
  navigationFinished: boolean;

  currentPrimaryPageId: string;
  currentTreeNodeId: string;
  currentSecondaryPageId: string = null;

  dataSource: DataSource;

  _focusedNode: any;
  wasClicked: boolean;
  dxTreeView: DxTreeView;

  isServiceWorkflow: boolean;

  // Pomocna promenna jako fronta pro rozbalovani uzlu stromu,
  // jelikoz je musime rozbalovat postupne
  private nodesToBeExpanded: TreeNode[] = [];
  private onLoadHandled = false;

  private subs = new SubSink();

  constructor(
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface,
    private navigationTreePath: NavigationTreePathService,
    private odataStoreService: NavigationTreeStoreService,
    public treeNavService: TreeNavigationUiService,
    private staticConfig: StaticConfigService,
    private log: LoggingService,
    private router: Router,
    private icons: IconsService
  ) {
    this.dataSource = new DataSource({
      store: this.odataStoreService.odataStore,
      onLoaded: () => {
        this.loading = false;
      },
      onLoadError: () => {
        this.loading = false;
      },
    });
  }

  ngOnInit() {
    this.waitWithInitializationUntilFirstNavigationEnd();
    this.displayLoadingIndicatorUntilDataArrives();
    this.subs.sink = this.navigation.currentPrimaryPageId$.subscribe((id) => {
      this.currentPrimaryPageId = id || '';
      this.isServiceWorkflow = id === 'service-workflow';
      const pagesWithoutTreeMenu = this.staticConfig.productConfig
        .pagesWithHiddenTreeMenu;
      if (pagesWithoutTreeMenu && pagesWithoutTreeMenu.includes(id)) {
        this.treeNavService.toggleTreeNav(false);
      }
      const pagesPreservingSubPages = this.staticConfig.productConfig
        .pagesPreservingSubPagesAfterTreeNavigation;
      if (pagesPreservingSubPages && pagesPreservingSubPages.includes(id)) {
        this.subs.sink = this.navigation.currentSecondaryPageId$.subscribe(
          (secondaryPageId) => {
            this.currentSecondaryPageId = secondaryPageId || null;
          }
        );
      }
    });
    this.subs.sink = this.navigation.currentTreeNodeId$.subscribe(
      (treeNodeId) => {
        this.log.debug(
          'TreeNavigationComponent',
          `Externi zmena uzlu stromu: ${treeNodeId}`
        );
        this.currentTreeNodeId = treeNodeId;
        this.navigationTreePath.getPathToTarget(treeNodeId).then((data) => {
          const treeNode: TreeNode = data.pop();
          if (this.dxTreeView && treeNodeId) {
            // Zpusobi oznaceni uzlu stromu i v pripade, ze je prechod zpusoben externe
            // napr. tlacitkem Zpet.
            if (treeNodeId === GlobalConstants.globalViewMark) {
              this.handleGlobalModeSelected();
            } else {
              this.navigation.setNextTreeNodeData(treeNode);
              this.expandSingleNode(
                treeNode,
                true,
                this.dxTreeView,
                treeNodeId
              );
            }
          }
        });
      }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  treeBarClicked(event) {
    this.handleGlobalModeSelected();
    this.treeNodeClicked.emit(event);
  }

  routerLink(treeNodeId) {
    const array = [
      '/',
      this.currentPrimaryPageId,
      treeNodeId,
      this.currentSecondaryPageId,
    ].filter(Boolean);
    return array;
  }

  onContentReady(event) {
    this.dxTreeView = event.component;
    this.removeDefaultLoadingIndicator(event.element);

    // Po nacteni stranky rozbalime menu podle URL
    this.log.debug(
      'TreeNavigationComponent.onContentReady',
      `Prvni nacteni bylo vyreseno? ${this.onLoadHandled}
       Strom je vykreslen v DOMu? ${
         document.querySelectorAll('.tree-node').length > 0
       }
       Aktualni treeNodeId? ${this.currentTreeNodeId}`
    );
    if (
      !this.onLoadHandled &&
      document.querySelectorAll('.tree-node').length > 0 &&
      this.currentTreeNodeId
    ) {
      this.log.debug(
        'TreeNavigationComponent.onContentReady',
        'Prvni nacteni pro ' + this.currentTreeNodeId
      );
      // Jelikoz se tato udalost vola vicekrat, kontrolujeme, jestli uz jsou pritomne prvky v navigaci
      if (this.currentTreeNodeId) {
        this.expandTreeNode(this.currentTreeNodeId, event.component);
      } else {
        // expand first node
        if (event.component.getNodes().length > 0) {
          const treeItem: any = event.component.getNodes()[0];
          this.expandTreeNode(treeItem.itemData.urowid, event.component);
          this.router.navigate([treeItem.itemData.urowid]);
        }
      }
      this.onLoadHandled = true;
    }
    this.dxTreeView.element().classList.add('special-tree-nav-selector');
  }

  onItemClick(event) {
    this.treeNodeClicked.emit(event);
    this.navigation.setNextTreeNodeData(event.itemData);
    this.expandSingleNode(event.itemData, true, event.component);
  }

  onItemCollapsed(event) {
    const itemContainer = event.itemElement.closest(
      '.dx-treeview-node-container'
    );
    setTimeout(() => {
      itemContainer.style.overflow = 'hidden';
    }, 250);
  }

  onItemExpanded(event) {
    const itemContainer = event.itemElement.closest(
      '.dx-treeview-node-container'
    );
    setTimeout(() => {
      itemContainer.style.overflow = 'visible';
    }, 250);
    if (this.nodesToBeExpanded.length > 0) {
      // Pokud mame v bufferu neco dalsiho na rozbaleni, pokracujeme v rozbalovani
      const treeNode = this.nodesToBeExpanded.pop();
      const noMoreNodesToBeExpaded = this.nodesToBeExpanded.length === 0;
      this.expandSingleNode(treeNode, noMoreNodesToBeExpaded, event.component);
    }
  }

  private displayLoadingIndicatorUntilDataArrives() {
    this.loadingIconSrc = this.icons.spinnerIconUrl;
  }

  private waitWithInitializationUntilFirstNavigationEnd() {
    // S nacitanim komponenty TreeNavigationComponent je nutne
    // pockat az do dokonceni prvni navigace - az pote je z url
    // dostupny parametr treeNodeId, ktery je nutny pro komponentu
    // Necekat vsak dele nez 2 vteriny
    this.navigationFinished = false;
    // console.time('navigation-tree');
    race([
      this.router.events.pipe(
        filter(
          (routerEvent) =>
            routerEvent instanceof NavigationEnd ||
            routerEvent instanceof NavigationError ||
            routerEvent instanceof NavigationCancel
        ),
        take(1)
      ),
      interval(2000),
    ]).subscribe(() => {
      // console.timeEnd('navigation-tree');
      this.navigationFinished = true;
    });
  }

  private removeDefaultLoadingIndicator(element: HTMLElement) {
    const iconElement: HTMLElement = element.querySelector(
      '.dx-loadindicator-icon'
    );
    if (iconElement) {
      const parent = iconElement.parentNode;
      parent.childNodes.forEach((item) => parent.removeChild(item));
    }
  }

  private expandTreeNode(
    treeNodeId: string,
    treeViewComponent: DxTreeView
  ): any {
    this.navigationTreePath
      .getPathToTarget(treeNodeId)
      .then((data: TreeNode[]) => {
        this.nodesToBeExpanded = [];
        if (data.length === 0) {
          return;
        }
        this.navigation.setNextTreeNodeData(data[data.length - 1]);
        this.nodesToBeExpanded = data.reverse();

        const treeNode: TreeNode = this.nodesToBeExpanded.pop();
        treeViewComponent.option('animationEnabled', false);

        // Rozbalime jednu, ostatni musime rozbalit az po rozbaleni prvni - v udalosti
        const noMoreNodesToBeExpaded = data.length === 0;
        this.expandSingleNode(
          treeNode,
          noMoreNodesToBeExpaded,
          treeViewComponent
        );
      })
      .catch((error) => {
        this.log.error('TreeNavigationComponent', error);
      });
  }

  /**
   * Expanduje nebo oznaci jeden uzel stromu.
   */
  private expandSingleNode(
    treeNode: TreeNode,
    noMoreNodesToBeExpaded: boolean,
    treeViewComponent: DxTreeView,
    treeNodeId?: string
  ): void {
    if (!treeNode) {
      this.log.warn('TreeNavigationComponent', 'treeNode is not defined!');
      return;
    }

    this.pathToNode.emit(this.generatePathToNode(treeNode, treeViewComponent));

    const foundNodes = treeViewComponent
      .element()
      .querySelectorAll(`#navid-${treeNode.urowid}`);
    if (foundNodes.length === 0) {
      this.expandTreeNode(treeNodeId, this.dxTreeView);
    } else if (!noMoreNodesToBeExpaded) {
      treeViewComponent.beginUpdate();
      // Musime preventivne nod zabalit, aby se vyvolala udalost onitemexpanded,
      // ktera pri rozbalenem nodu nefungoval
      treeViewComponent.collapseItem(foundNodes.item(0));
      treeViewComponent.expandItem(foundNodes.item(0));
      treeViewComponent.endUpdate();
    } else {
      this._focusedNode = treeNode;

      // Zmenime zvyrazneni podle tipu:
      // https://www.devexpress.com/Support/Center/Question/Details/T224423/
      // dxtreeview-losing-selected-item-backgroud-when-losing-focus
      treeViewComponent
        .element()
        .querySelectorAll('.tree-node')
        .forEach((node: HTMLElement) => {
          node.classList.remove('is-active');
        });

      foundNodes.item(0).classList.add('is-active');

      treeViewComponent.option('animationEnabled', true);
    }
  }

  private handleGlobalModeSelected() {
    this.dxTreeView
      .element()
      .querySelectorAll('.tree-node')
      .forEach((node: HTMLElement) => {
        node.classList.remove('is-active');
      });
  }

  private generatePathToNode(
    treeNode: TreeNode,
    treeViewComponent: any
  ): TreeNode[] {
    const dxNode = this.getDxNodeForUrowid(treeNode.urowid, treeViewComponent);
    if (dxNode) {
      let currentNode = dxNode;
      const nodesHierarchy: TreeNode[] = [];
      while (currentNode != null) {
        nodesHierarchy.push({
          caption: currentNode.text,
          id: currentNode.key,
        });
        currentNode = currentNode.parent;
      }
      return nodesHierarchy.reverse();
    }
    return [];
  }

  private getDxNodeForUrowid(urowid: string, treeView: DxTreeView) {
    if (treeView) {
      const dxTreeNodes = treeView.getNodes();
      return this.findNodeRecursively(dxTreeNodes, urowid);
    } else {
      return null;
    }
  }

  private findNodeRecursively(dxTreeNodes: any[], urowid: string) {
    for (const node of dxTreeNodes) {
      if (node.key === urowid) {
        return node;
      } else {
        if (node.items) {
          const found = this.findNodeRecursively(node.items, urowid);
          if (found) {
            return found;
          }
        }
      }
    }
    return null;
  }
}
