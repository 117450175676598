<ng-container *transloco="let t">
  <div class="wrapper u-no-select" data-cy="user-session">
    <a
      id="popover-target"
      (click)="showPopover()"
      routerLinkActive="o-link--active"
      [ngClass]="{
        'user-link-active': popoverVisible
      }"
      class="user-link o-link o-link--on-dark"
    >
      <domat-icon class="icon" name="user"></domat-icon>
      <span class="user-name">
        {{
          [initialConfig.user.firstName, initialConfig.user.lastName]
            .filter(Boolean)
            .join(' ')
        }}
      </span>
    </a>

    <dx-popover
      class="popover"
      target="#popover-target"
      position="top"
      [width]="300"
      [shading]="false"
      [(visible)]="popoverVisible"
      ><div data-cy="user-session-popup">
        <div class="user-info">
          <div class="icon-container">
            <domat-icon class="user-icon" size="h1" name="user"></domat-icon>
          </div>
          <div class="info">
            <span>{{
              initialConfig.user.firstName + ' ' + initialConfig.user.lastName
            }}</span
            ><br /><span> {{ initialConfig.user.email }} </span>
          </div>
        </div>
        <div class="separator"></div>
        <div class="menu">
          <div data-cy="user-session-settings">
            <a class="link" (click)="userSettings()"
              ><domat-icon name="settings"></domat-icon
              ><span class="link-text">{{
                t('shell.userSession.menu.settings')
              }}</span></a
            >
          </div>
          <ng-container *ngIf="showHelp">
            <div data-cy="user-session-help">
              <a
                class="link"
                [href]="t('shell.userSession.helpLink')"
                target="_blank"
                ><domat-icon name="help"></domat-icon
                ><span class="link-text">{{
                  t('shell.userSession.menu.help')
                }}</span></a
              >
            </div>
          </ng-container>
          <div data-cy="user-session-about">
            <a class="link" (click)="toggleAbout()"
              ><domat-icon name="about"></domat-icon
              ><span class="link-text">{{
                t('shell.userSession.menu.policy')
              }}</span></a
            >
          </div>
        </div>
        <div class="separator"></div>
        <div class="menu">
          <a
            class="link"
            data-cy="user-session-link"
            href="/Account/LogOff"
            (click)="logOff()"
            ><domat-icon name="signOut"></domat-icon
            ><span class="link-text">{{
              t('shell.userSession.menu.logout')
            }}</span></a
          >
        </div>
        <div class="separator"></div>
        <div class="footer">
          <span class="copyright">{{ copyright }}</span
          ><br />
          <span
            class="version"
            (mouseenter)="toggleTooltip()"
            (mouseleave)="toggleTooltip()"
          >
            {{ initialConfig.environment.applicationVersion
            }}<dx-tooltip
              class="popover"
              target=".version"
              [(visible)]="versionVisible"
              [closeOnOutsideClick]="false"
            >
              <span
                >{{
                  'frontend: ' + initialConfig.environment.frontendVersion
                }} </span
              ><br />
              <span
                >{{ 'backend: ' + initialConfig.environment.backendVersion }}
              </span>
            </dx-tooltip>
          </span>
        </div>
      </div>
    </dx-popover>

    <dx-popup
      [width]="'90vw'"
      [height]="'90vh'"
      [showTitle]="true"
      title=""
      [dragEnabled]="false"
      [closeOnOutsideClick]="true"
      [(visible)]="showAboutPopup"
      (onContentReady)="onContentReady($event)"
      [deferRendering]="false"
    >
      <dx-scroll-view width="100%" height="100%">
        <domat-application-info></domat-application-info>
      </dx-scroll-view>
    </dx-popup>
  </div>
</ng-container>
