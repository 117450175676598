import { Component, Inject, OnInit } from '@angular/core';
import { Params } from '@angular/router';
import {
  AppPage,
  NAVIGATION_SERVICE,
  NavigationServiceInterface,
} from '@domat/shared/data-access';

@Component({
  selector: 'domat-main-navigation',
  template: `
    <domat-primary-navigation
      [pages]="primaryNavigation"
      [currentPrimaryPageId]="currentPrimaryPageId"
      [currentTreeNodeId]="currentTreeNodeId"
    >
    </domat-primary-navigation>
    <domat-secondary-navigation
      [pages]="secondaryNavigation"
      [currentPrimaryPageId]="currentPrimaryPageId"
      [currentTreeNodeId]="currentTreeNodeId"
      [currentSecondaryPageId]="currentSecondaryPageId"
      [currentQueryParams]="currentQueryParams"
    >
    </domat-secondary-navigation>
  `,
  styles: [],
})
export class MainNavigationComponent implements OnInit {
  primaryNavigation: AppPage[];
  secondaryNavigation: AppPage[];

  currentPrimaryPageId: string;
  currentTreeNodeId: string;
  currentSecondaryPageId: string;
  currentQueryParams: Params;

  constructor(
    @Inject(NAVIGATION_SERVICE) private navigation: NavigationServiceInterface
  ) {}

  ngOnInit() {
    this.primaryNavigation = this.navigation.getPrimaryNavigationItems();
    this.navigation.currentPrimaryPageId$.subscribe((id) => {
      this.currentPrimaryPageId = id;
      this.secondaryNavigation =
        this.navigation.getSecondaryNavigationItems(id);
      this.navigation.currentQueryParams$.subscribe((params) => {
        this.currentQueryParams = params;
      });
    });
    this.navigation.currentTreeNodeId$.subscribe((treeNodeId) => {
      this.currentTreeNodeId = treeNodeId;
    });
    this.navigation.currentSecondaryPageId$.subscribe((id) => {
      this.currentSecondaryPageId = id;
    });
  }
}
