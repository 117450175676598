import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { UrlUtilsService } from '@domat/shared/utils';
import {
  Translation,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { environment } from '../../environments/environment';
import cacheBusting from '../../../../../i18n-cache-busting.json';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private urlUtils: UrlUtilsService) {}

  getTranslation(lang: string) {
    const location = this.urlUtils.asset(
      `i18n/${lang}.json?v=${cacheBusting[lang]}`
    );
    return this.http.get<Translation>(location);
  }
}

@NgModule({
  imports: [TranslocoMessageFormatModule],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'cs'],
        defaultLang: 'cs',
        fallbackLang: ['en', 'cs'],
        missingHandler: {
          // It will use the first language set in the `fallbackLang` property
          useFallbackTranslation: true,
          allowEmpty: true,
        },
        reRenderOnLangChange: true,
        prodMode: environment.production,
      }),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
