import { Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root',
})
export class PerformanceMeasurementService {
  constructor(private log: LoggingService) {}

  start(name: string) {
    if (isPlatformBrowser(PLATFORM_ID)) {
      window.performance.mark(name);
    }
  }

  measure(startName: string) {
    if (isPlatformBrowser(PLATFORM_ID)) {
      window.performance.measure(startName, startName);
      this.log.debug(
        'Performance',
        `${startName} - ${Math.round(
          window.performance.getEntriesByName(startName, 'measure')[0]?.duration
        )} ms`
      );
    }
  }
}
